function railHelper () { }

railHelper.isTrainOnly = function (itineraries) { 
    var isTrainOnly = true;

    itineraries.forEach((itinerary) => {
        if (itinerary.segments.any((segment) => { return segment.equipment.type != 3 }))
            isTrainOnly = false;
    });

    return isTrainOnly;
};


railHelper.isPreachItinerary = function (itinerary) {
    return itinerary.segments.any((segment) => { return segment.equipment.type == 3 }) && itinerary.segments.any((segment) => { return segment.equipment.type <= 1 })
};

railHelper.FillIsTrainItineraries = function (itineraries) {
    itineraries.forEach((itinerary) => {
        itinerary.isTrainOnly = itinerary.segments.every((segment) => { return segment.equipment.type == 3 });
    });
};

railHelper.FillIsPreachItineraries = function (itineraries) {
    itineraries.forEach((itinerary) => {
        itinerary.isPreach = itinerary.segments.any((segment) => { return segment.equipment.type == 3 }) && itinerary.segments.any((segment) => { return segment.equipment.type <= 1});
    });
};

railHelper.IsTrain = function (equipment) {
    if (equipment.toUpperCase() == "TRN") return true;
    if (equipment.toUpperCase().startsWith("TGV")) return true;


    return false;
};

railHelper.IsTrainWithList = function (equipment, equipmentList) {
    var isTrain = false;
    if (equipment == null) return false;

    equipmentList.forEach((x) => {
        if (x.type) {
            if ((equipment.toUpperCase() == x.type.toUpperCase()) && (x.equipmentType == 3)) {
                isTrain = true;
            }
        }
    });

    return isTrain;
};