webClient.directive('ngPurchaseStepThree', ['$window', '$timeout', '$i18next', '$http', 'templateService', 'tseApiClient', 'tseUtil',
    function ($window, $timeout, $i18next, $http, templateService, tseApiClient, tseUtil) {
        var directive = {};
        directive.restrict = 'EA';
        directive.scope = {
            paxinfo: "=",
            buyer: "=",
            contact: "=",
            total: "=",
            bookingrequest: "=",
            bkgdata: "=",
            isloggedin: "=",
            isairbus: "=",
            hideinsurance: "=",
            enablefreeflex: "="
        };
        directive.link = function ($scope, $element) {

            templateService.compile($scope, $element, 'tpl/_PurchaseStep3TPL.html');

            var PAYMENTSMODE = {
                CASINO: "CAS",
                PAYPAL: "PAYPAL",
                BAN: "BAN"
            };

            $scope.getUrl = $window.getUrl;

            $scope.siteType = $window.site.type;
            $scope.getTemplateUrl = $window.getTemplateUrl;
            $scope.getStaticFolder = $window.getStaticFolder;
            $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
            $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
            $scope.getUrl = $window.getUrl;
            $scope.getText = function (key) { return $window.culture.texts[key]; };
            $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
            $scope.airbusExternalloginInfo = $window.site.loginInfo;
            $scope.countriesCode = $window.countriesCode;
            $scope.bookingrequest.purchaseId = $scope.bkgdata.purchaseResponse.purchaseId;
            $scope.displayDiscount = $scope.bkgdata.purchaseResponse.informationData["DisplayDiscount"]
                || $scope.bkgdata.purchaseResponse.informationData["displayDiscount"];

            $scope.lng = window.site.culture;
            $scope.partnerName = window.partnerName;
            $scope.hideMoreInfos = window.partnerName === 'adp' || window.partnerName === 'thomascook' || window.partnerName === 'aeroports-voyages' || window.partnerName === 'europa' || window.partnerName === 'jereserve.com' || window.partnerName === 'nice_airport' || window.partnerName === 'visa' || window.partnerName === 'visabusiness' || window.partnerName === 'bynativ.com' || window.partnerName === 'ekoya' || window.partnerName === 'turkmenistan' || window.partnerName === 'ekotrip' || window.partnerName === 'kiwipal' || window.partnerName === 'france' || window.partnerName === 'volcdiscount' || window.partnerName === 'havas';
            $scope.enableUpSellProducts = $window.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';

            //TOREVIEW
            $scope.hasFlightHotelFormSummary = hasCookie("FlightHotelFormSummary") && $window.getUrlVars()['hotelSummary'] === 'true';
            $scope.enableDpkMargin = $window.enableDpkMargin = $window.enableHotelMargin = $scope.hasFlightHotelFormSummary;
            if ($scope.loginInfo) {
                $scope.loginInfo.ProductDpk = true;
            }

            $scope.isKiwi = $scope.bkgdata.purchaseResponse.kiwi;
            $scope.isServair = window.site.partnerId === "servair";
            $scope.paxes = [];
	        $scope.noDiscountMessage = false;

            $scope.reactivateDiscountError = false;
            $scope.reactivateDiscountMessage = false;
            $scope.activeTab = true;
            $scope.useNewCvCo = $window.site.type != 2 && !$scope.enableUpSellProducts;
            $scope.setActiveTab = function () {
                $scope.activeTab = !$scope.activeTab;
            };

            if (typeof $window.marketCode === "undefined") {
                $scope.marketCode = '';
            } else {
                $scope.marketCode = $window.marketCode.toUpperCase();
            }

            if (typeof marketCode !== "undefined" && marketCode === "BE" || window.partnerName === "wonderbox" && /be-\w{2}/.test(window.site.partnerId) || window.partnerName.startsWith('neckermann')) {
                $scope.minPostcode = 4;
            }
            else {
                $scope.minPostcode = 5;
            }

            if ($scope.bkgdata.purchaseResponse.proposalsRequested.flexies && $scope.bkgdata.purchaseResponse.proposalsRequested.flexies[0] && !$scope.isServair) {
                $scope.enableFreeFlex = $scope.bkgdata.purchaseResponse.proposalsRequested.flexies[0].propose === 2;
            }

            if ($scope.bkgdata.bookingRequest.additionalData["cdiscountCustomerGuid"]) {
                if (!$scope.bookingrequest.additionalData)
                    $scope.bookingrequest.additionalData = {};
                $scope.bookingrequest.additionalData["cdiscountCustomerGuid"] = $scope.bkgdata.bookingRequest.additionalData["cdiscountCustomerGuid"];
            }

            //Params
            var params = $window.getUrlVars();
            if (params['lastname'])
                $scope.buyer.lastName = decodeURIComponent(params['lastname']);
            if (params['firstname'])
                $scope.buyer.firstName = decodeURIComponent(params['firstname']);
            if (params['phone']) {
                var input = decodeURIComponent(params['phone']);
                if (libphonenumber.isValidNumber(input)) {
                    var phoneNb = libphonenumber.parse(input);
                    $scope.paxinfo[0].phone = phoneNb.phone;
                    $scope.paxinfo[0].phoneCountryCode = $window.countriesCode.filter(function (c) { return c.iso === phoneNb.country; })[0].code;

                    $scope.contact.telCountryCode = $window.countriesCode.filter(function (c) { return c.iso === phoneNb.country; })[0].code;
                    $scope.contact.telNumber = phoneNb.phone;
                }
            }

            if (params['birthdate']) {
                var dateString = $scope.datelastarrival;
                var birthDate = moment(params['birthdate']);
                var dateMin = moment(dateString).subtract(100, 'years');

                if (window.site.partnerId === "visabusiness") var dateMax = moment(dateString).subtract(18, 'years');
                else var dateMax = moment(dateString).subtract(12, 'years'); // NICO

                if (birthDate > dateMin && birthDate < dateMax) {
                    $scope.paxinfo[0].birthDate = moment(params['birthdate'], "DD/MM/YYYY").format("DD/MM/YYYY");
                    $scope.buyer.birthDate = moment(params['birthdate'], "DD/MM/YYYY").format("DD/MM/YYYY");
                }
            }

            if (params['title']) {
                if (params['title'] === "1" || params['title'] === "2") {
                    $scope.paxinfo[0].title = params['title'];
                    $scope.buyer.title = params['title'];
                }
            }

            if (params['address']) {
                $scope.buyer.address = decodeURIComponent(params['address'].replace(/\+/g, '%20'));
            }

            if (params['zipcode']) {
                $scope.buyer.zipCode = params['zipcode'];
            }

            if (params['city']) {
                $scope.buyer.city = decodeURIComponent(params['city'].replace(/\+/g, '%20'));
            }

            //Flags
            $scope.enableHolidaysVoucher = $window.enableHolidaysVoucher;
            $scope.enable4xNonProposalMessage = $window.enable4xNonProposalMessage;

            if ($scope.bkgdata.paymentMethods != null) {
                $scope.bkgdata.paymentMethods.each(function (item) {
                    console.log("-------------");
                    console.log(item);
                    console.log("-------------");
                    if (item.paymentMethodReference === "FAC" && item.enabled === true) {         // Facily Pay 3x
                        $scope.facilyPayAvailable = true;
                        $scope.facilyPayId = item;
                        $scope.facilyPayAmount3X = item.facilityPay.facilyPayAmount3X;
                        $scope.facilyPayAmount4X = item.facilityPay.facilyPayAmount4X;
                    }
                    if (item.paymentMethodReference === "CAS" && item.enabled === false) {
                        $scope.showBanqueCasinoUnavailable = true;
                        if ($scope.bkgdata.banqueCasinoMessage == undefined) {
                            $scope.bkgdata.banqueCasinoMessage = false;
                        }
                        if ($scope.bkgdata.banqueCasinoCustomerUnauthorized == undefined) {
                            $scope.bkgdata.banqueCasinoCustomerUnauthorized = false;
                        }
                    }
                    if (item.paymentMethodReference === "CAS" && item.enabled === true) {         // Banque Casino
                        $scope.banqueCasinoId = item;
                        $scope.banqueCasinoId.pspId = item.banqueCasino.pspId;
                        $scope.banqueCasinoId.pspAccountId = item.banqueCasino.pspAccountId;
                        $scope.banqueCasinoId.paymentType = item.paymentMethodReference;

                        if ($scope.bkgdata.banqueCasinoMessage == undefined) {
                            $scope.bkgdata.banqueCasinoMessage = false;
                        }
                        if ($scope.bkgdata.banqueCasinoCustomerUnauthorized == undefined) {
                            $scope.bkgdata.banqueCasinoCustomerUnauthorized = false;
                        }

                        if (item.banqueCasino && item.banqueCasino.paymentSchedule != null) {
                            $scope.bkgdata.banqueCasino = 2; //is good
                            $scope.bkgdata.banqueCasinoSchedule = item.banqueCasino;
                            $scope.banqueCasinoAvailable = true;
                            $scope.bkgdata.showBanqueCasino = 1;

                        }
                        else {
                            $scope.bkgdata.banqueCasino = 3; //error
                            $scope.bkgdata.banqueCasinoSchedule = null;
                            $scope.bkgdata.showBanqueCasino = 0; //normal
                            $scope.banqueCasinoAvailable = false;
                        }

                        if ($scope.bkgdata.purchaseResponse.productIsTrain == true) {
                            $scope.bkgdata.banqueCasino = 3; //error
                            $scope.bkgdata.banqueCasinoSchedule = null;
                            $scope.bkgdata.showBanqueCasino = 0; //normal
                            $scope.banqueCasinoAvailable = false;
                        }

                        //$scope.prepareBanqueCasinoData();
                    }
                    if (item.paymentMethodReference === "PAYPAL" && item.enabled === true) {      // Paypal
                        $scope.paypalAvailable = true;
                        $scope.paypalId = item;
                        $scope.bkgdata.paypalToken = item.paypal.token;
                    }
                    if (item.paymentMethodReference === "IDE" && item.enabled === true) {

                        $scope.idealAvailable = true;
                        $scope.idealId = item;
                    }
                    if (item.paymentMethodReference === "BAN" && item.enabled === true) {         // BANCONTACT
                        $scope.bancontactAvailable = true;
                        $scope.bancontactId = item;
                    }

                    if (item.paymentMethodReference === "CB" && item.transactionMethod === 1 && item.enabled === true) {        // CB data in MF

                        $scope.internCBAvailable = true;
                        $scope.internCBCards = item.bankCard.bankCards.groupByKey('bankCardType');
                        $scope.internCBId = item;
                        $scope.internCBId.pspId = item.bankCard.pspId;
                        $scope.internCBId.pspAccountId = item.bankCard.pspAccountId;
                        $scope.internCBId.paymentType = item.bankCard.bankCards[0].bankCardType;

                        //AMEX
                        if ($scope.internCBCards['AMEX']) {
                            var clonedItem = JSON.parse(JSON.stringify(item));//clone object
                            $scope.internAmexId = clonedItem;
                            $scope.internAmexId.pspId = clonedItem.bankCard.pspId;

                            var amex = clonedItem.bankCard.bankCards.filter(function (b) { return b.bankCardType === 'AMEX'; });

                            if (amex.length > 0) {
                                $scope.internAmexId.pspAccountId = amex[0].pspAccountId;
                                $scope.internAmexId.paymentType = amex[0].bankCardType;
                            }
                        }
                    }

                    if (item.paymentMethodReference === "CB" && item.transactionMethod === 2 && item.enabled === true) {      // CB data in other page
                        $scope.externalCBAvailable = true;
                        $scope.externalCBCards = item.bankCard.bankCards.groupByKey('bankCardType');
                        $scope.externalCBId = item;
                    }
                });


                console.log("$scope.internCBCards");
                console.log($scope.internCBCards);

                if ($scope.enable4xNonProposalMessage &&
                    $scope.showBanqueCasinoUnavailable &&
                    !$scope.bkgdata.banqueCasinoCustomerUnauthorized &&
                    !$scope.bkgdata.banqueCasinoMessage) {

                    $(document).trigger('casino-not-available');
                }
            }

            $scope.bookingrequest.paymentMode = $scope.bookingrequest.paymentMode || ($scope.bkgdata.showBanqueCasino == 1 ? undefined : 1);
            $scope.bookingrequest.bankCasino = $scope.bookingrequest.bankCasino || { maidenName: '', departmentOfBirth: '', birthDate: '' };

            //Holidays voucher
            if ($scope.bookingrequest && $scope.bookingrequest.holidayVoucher) {
                $scope.bookingrequest.holidayVoucher = $scope.bookingrequest.holidayVoucher;
                $timeout(function () {
                    var opener = $('#i-check-f1')[0];
                    $window.FSmoothOpen({ oOpener: opener, iSmooth: 'i-smooth-payment-ancv' });
                }, 300);
            }
            else {
                $scope.bookingrequest.holidayVoucher = false;
            }

            var arrPaymentSchedule = [];

            $scope.isCasinoPayment = function () {
                if ($scope.bookingrequest.paymentMethod != null) {
                    return ($scope.bookingrequest.paymentMethod.paymentMethodReference === PAYMENTSMODE.CASINO);
                }
                return false;
            };

            $scope.createBookingRequestAdditionalDataEmpty = function () {
                $scope.bookingrequest.additionalData = {};
            };

            if ($window.isAirbus) {
                $scope.addAirbusExternalloginInfoInBookingAdditionalData = function () {
                    $scope.bookingrequest.additionalData["airbus.firstname"] = $scope.airbusExternalloginInfo.Prenom;
                    $scope.bookingrequest.additionalData["airbus.lastname"] = $scope.airbusExternalloginInfo.Nom;
                    $scope.bookingrequest.additionalData["airbus.matricule"] = $scope.airbusExternalloginInfo.Matricule;
                    $scope.bookingrequest.additionalData["airbus.matricule2"] = "";
                    $scope.bookingrequest.additionalData["airbus.insurance"] = $scope.bookingrequest.isAirbusInsuranceSelected;
                    $scope.bookingrequest.additionalData["airbus.portable"] = $scope.airbusExternalloginInfo.Portable;
                    $scope.bookingrequest.additionalData["airbus.telephone"] = $scope.airbusExternalloginInfo.Telephone;
                    $scope.bookingrequest.additionalData["airbus.referrer"] = $scope.airbusExternalloginInfo.Referrer;
                    $scope.bookingrequest.additionalData["airbus.idAdherent"] = $scope.airbusExternalloginInfo.IdAdherent;
                };

                $scope.addAirbusPayBalanceOptionInBookingAdditionalData = function () {
                    $scope.bookingrequest.additionalData["airbus.payBalanceOption"] = $scope.bookingrequest.airbusPayOption;
                };
            }

            $scope.setUpPayerWithAirbusLoginInformation = function () {
                $scope.buyer.firstName = $window.site.loginInfo.Prenom;
                $scope.buyer.lastName = $window.site.loginInfo.Nom;
                $scope.buyer.matricule = $window.site.loginInfo.Matricule;
            };

            $scope.createBookingRequestAdditionalDataEmpty();

            if ($window.isAirbus) {
                $scope.addAirbusExternalloginInfoInBookingAdditionalData();
                $scope.setUpPayerWithAirbusLoginInformation();
            }

            $scope.setBuyerCountryISO = function (isoCode) {
                $scope.buyer.country = isoCode;
            };

            $scope.setContactPhoneCountryCode = function (phoneCode) {
                $scope.contact.telCountryCode = phoneCode;
            };

            if ($scope.buyer) {
                if ($scope.buyer.country == undefined || $scope.buyer.country == null) {
                    $scope.setBuyerCountryISO($scope.countryCode);
                }

                if ($scope.buyer.telCountryCode == undefined || $scope.buyer.telCountryCode == null) {
                    $scope.setContactPhoneCountryCode("33");
                }
            }



            //select default payment method
            $scope.$watch('$viewContentLoaded', function () {
                var payments = $('#i-form-payment [ng-click]');
                if (payments.length === 1 || window.partnerName === 'wonderbox') {
                    setTimeout(function () { payments[0].click(); });
                }
            });



            $scope.ceText = null;
            $scope.showCEBlock = false;
            //var CESite = 5;

            //if ($scope.siteType == CESite) {
            //    $scope.showCEBlock = $window.site.loginInfo.CeWebBox;
            //    $scope.ceText = $window.site.loginInfo.CeText;
            //}

            $timeout(function () {
                if ($scope.bkgdata.paymentMethods && $scope.bkgdata.paymentMethods.length > 0) {

                    var selected = $scope.bkgdata.paymentMethods.first(function (i) {
                        return i.paymentMethodId === $scope.bkgdata.bookingRequest.paymentMethodId;
                    });

                    if (selected) {
                        if (selected.paymentMethodReference == "CAS") {
                            selected = null;
                        }
                    }

                    if (selected != undefined && selected != null) {


                        $("#i-radio-" + selected.paymentMethodReference + selected.transactionMethod).prop("checked", true);


                        if (selected.paymentMethodReference == "CAS") {
                            $window.FSmoothPayment({ vClicked: '4x' });
                            $("#i-radio-" + selected.paymentMethodReference + "1").prop("checked", true);
                        }
                    }

                    if ($scope.bookingrequest.paymentMode == 3) {
                        $("#i-radio-CAS1").prop("checked", true);
                        $window.FSmoothPayment({ vClicked: '4x' });
                    }

                    if ($scope.bkgdata.banqueCasinoMessage) {
                        $("#pay-banqueCasino").addClass("c-opa-50");
                        $("#i-radio-CAS1").attr('disabled', true);

                        $(document).trigger('casino-scoring-ko');
                    }
                }
                else {
                    $window.redoSearch();
                }
            }, 300);

            $scope.prepareBanqueCasinoData = function () {
                if ($scope.bkgdata.banqueCasinoSchedule != null) {
                    arrPaymentSchedule = $scope.bkgdata.banqueCasinoSchedule.paymentSchedule;
                    arrPaymentSchedule = arrPaymentSchedule.groupByKey('amount');
                    arrPaymentSchedule = objectToArray(arrPaymentSchedule);
                    arrPaymentSchedule.each(function (item) {
                        if (isArray(item.value))
                            item.mindate = item.value.min(function (vitem) {
                                var dateNum = moment(vitem.date).toDate().getTime();
                                return dateNum;
                            });
                        else
                            item.mindate = moment(item.value.date).toDate().getTime();
                    });
                    arrPaymentSchedule = arrPaymentSchedule.orderBy(function (item) { return item.mindate; });
                    $window.arrPaymentSchedule = arrPaymentSchedule;
                    $scope.arrsPayment = arrPaymentSchedule;
                }
                $scope.bankCasinoMessage = false;
            };

            $scope.prepareBanqueCasinoData();

            $("#i-form").attr("action", "../airPayment/" + $scope.bookingrequest.requestId);

            var countriesCodeKey = $scope.countriesCode.groupByKey('iso');

            $scope.countryName = ($scope.buyer) ? $scope.buyer.country : null;

            if ($scope.countryName) {
                var country = countriesCodeKey[$scope.countryName];

                if (country.length > 0)
                    $scope.countryName = country[0].name;
                else
                    $scope.countryName = country.name;
            }

            countriesCodeKey = $scope.countriesCode.groupByKey('code');

            var langCaps = window.market ? window.market : lng.substring(3);

            if ($window.site.partnerId.substring(0, 2) === "be") {
                langCaps = "BE";
            }

            if ($window.partnerName == 'neckermann') {
                langCaps = "BE";
            }

            var langItem = window.countriesCode.filter(function (item) { return item.iso.toLowerCase() === langCaps.toLowerCase(); }).first();

            $scope.countryCode = langItem.iso;
            $scope.countryName = langItem.name;
            $scope.contact.telCountryCode = langItem.code;
            $scope.setBuyerCountryISO(langItem.iso);



            for (var i = 0; i < $scope.paxinfo.length; i++) {
                $scope.paxes[i] = $scope.paxinfo[i].firstName + " " + $scope.paxinfo[i].lastName;
            }


            $scope.setReady = function (item) {
                var go = true;

                if (item == 2) {
                    //post ici
                    if ($window.XLFormValid({ iCont: 'i-form' }) == false || !$scope.isValidForNextStep()) {
                        go = false;
                    }

                    if ($window.isAirbus) {
                        if ($window.XLFormValid({ iCont: 'airbus-payment-form' }) == false || $scope.bkgdata.bookingRequest.airbusPayOption == undefined) {
                            go = false;
                        }
                    }
                    // To do changer sesstionstroage par Scope
                    try {
                        var flexyId = $window.sessionStorage.getItem("inssurance.flexy.selectedOffer");
                        var requestId = $window.sessionStorage.getItem("inssurance.flexyRequest.selectedOffer");
                        if (flexyId != "null" && requestId != "null" && $scope.bkgdata.request.requestId == requestId && $scope.bkgdata.bookingRequest.flexyId != flexyId) {
                            $scope.bkgdata.bookingRequest.flexyId = flexyId;
                        }
                    }
                    catch (error) {
                        console.log(error);
                    }

                    //If not select any payment option
                    if ($scope.bkgdata.bookingRequest.paymentMethod == undefined || $scope.bkgdata.bookingRequest.paymentMethodId == undefined) {
                        go = false;
                        $window.XLConfirm({ vTitle: $i18next.t('airbooking.selectpayment'), vTextYes: "OK", vHideNo: true });

                        $(document).trigger('input-error', [$('#i-form-payment')[0], $i18next.t('airbooking.selectpayment')]);
                    }
                    else if (document.getElementById("i-check-cgv") && $("#i-check-cgv")[0].checked == false) {
                        go = false;
                        $window.XLConfirm({ vTitle: $i18next.t('airbooking.checkcgv'), vTextYes: "OK", vHideNo: true });
                    }
                    //If CE
                    if ($scope.showCEBlock) {
                        if ($window.XLFormValid({ iCont: 'i-form-ce' }) == false) {
                            go = false;
                        }
                    }
                } else {
                    if (item == 0) {
                        //Add InspecLet Modifier P1 tag
                        window.sendInspectlet('tagSession', { ModifierP1: true });
                    }
                    if (item == 1) {
                        //Add InspecLet Modifier P2 tag
                        window.sendInspectlet('tagSession', { ModifierP2: true });
                    }

                    $scope.$emit("setReadyStep", { step: item });
                    go = false;
                }
                if (go) {
                    $scope.bkgdata.bookingRequest.contact.phone = libphonenumber.parsePhoneNumberFromString("+{0}{1}".format($scope.bkgdata.bookingRequest.contact.telCountryCode, $scope.bkgdata.bookingRequest.contact.telNumber)).format("INTERNATIONAL").replace(/\s/g, "");

                    if ($scope.siteType != 2) {

                        //Guardar informacion del pagador y contacto en session
                        var paxHistory = tseUtil.Session.get('flightPaxHistory');
                        paxHistory.buyer = $scope.buyer;
                        paxHistory.contact = $scope.contact;

                        tseUtil.Session.set('flightPaxHistory', paxHistory);
                    }
                    if ($scope.bkgdata.bookingRequest.paymentMethod.paymentMethodReference == "CAS") {
                        if ($scope.buyer.title == '2') {
                            $("#i-nameSingleWoman").attr('data-check', "{check: 'required'}");
                        }

                        if ($window.XLFormValid({ iCont: 'i-form-payment' })) {

                            $window.FLoading({ vAction: 'show' });

                            if (window.disableBlue === true) {
                                $scope.casinoScoring();//legacy call to api
                            }
                            else {
                                //check eligibility
                                $scope.bookingrequest.paymentMethod.paymentType = "CASINOCB4X";
                                $scope.$emit("createBooking", { request: $scope.bookingrequest });
                            }
                        }
                    }
                    else {
                        //*****************************************************************************************
                        //Merovingio Step 3
                        var bReq = $window.$scope.bkgData.bookingRequest;
                        bReq.buyer = bReq.buyer || { title: null, firstName: null, lastName: null, address: null, postalCode: null, city: null, country: null, telNumber: null, email: null, discountCode: null, holidayVoucher: null, bankCasino: {} };
                        bReq.contact = bReq.contact;
                        //*****************************************************************************************
                        $scope.$emit("createBooking", { request: $scope.bookingrequest });
                    }
                }
            };

            $scope.casinoScoring = function () {
                            //Bank Casino Scoring
                            var CasinoScoringRQ = {
                                purchaseId: $scope.bkgdata.purchaseResponse.purchaseId,
                                requestId: $scope.bkgdata.request.requestId,
                                proposalIndex: $scope.bkgdata.purchaseResponse.proposalsRequested.index,
                                contact: $scope.bkgdata.bookingRequest.contact,
                                buyer: $scope.bkgdata.bookingRequest.buyer,
                                partnerMargin: 0,
                                opacityData: [],
                                maidenName: $scope.bookingrequest.bankCasino.maidenName,
                                departmentOfBirth: $scope.bookingrequest.bankCasino.departmentOfBirth,
                                passengers: [],
                                additionalData: {}
                            };

                            if ($scope.bookingrequest.bankCasino.birthDate != undefined) {
                                auxBankCasinoBirthDate = moment($scope.bookingrequest.bankCasino.birthDate, "DD-MM-YYYY");
                                auxBankCasinoBirthDate = moment($scope.bookingrequest.bankCasino.birthDate, "DD-MM-YYYY");
                                CasinoScoringRQ.birthDate = auxBankCasinoBirthDate.format("YYYY-MM-DD");
                            }
                            //TODO FFV2: Insurance (and Flexy)
                            if ($scope.bkgdata.bookingRequest.insurance != undefined) {
                                CasinoScoringRQ.insuranceId = $scope.bkgdata.bookingRequest.insurance.id;
                            }
                            if ($scope.bkgdata.flexyId != undefined) {
                                CasinoScoringRQ.flexyId = $scope.bkgdata.flexyId;
                            }

                            if ($scope.bkgdata.validDiscountCode != undefined && $scope.bkgdata.validDiscountCode != null)
                                CasinoScoringRQ.additionalData["DiscountCode"] = $scope.bkgdata.validDiscountCode;

                            //Procesar maletas adicionales por pasajero
                            $scope.bkgdata.bookingRequest.passengers.each(function (p) {
                                var auxPaxPassportExpireDate = null;
                                var auxPaxBirthDate = moment(p.birthDate, "DD-MM-YYYY");

                                if (p.passportExpireDate != undefined)
                                    auxPaxPassportExpireDate = moment(p.passportExpireDate, "DD-MM-YYYY");

                                var auxPasenger = {
                                    firstName: p.firstName,
                                    lastName: p.lastName,
                                    birthDate: auxPaxBirthDate.format("YYYY-MM-DD"),
                                    index: p.index,
                                    paxType: p.paxType,
                                    phoneCountryCode: p.phoneCountryCode,
                                    ptc: p.ptc,
                                    title: p.title,
                                    passportNumber: p.passportNumber,
                                    passportCountry: p.passportCountry,
                                    additionalBaggages: [],
                                    fidelityCardNumber: p.fidelityCardNumber,
                                    fidelityCard: p.fidelityCardType
                                };

                                if (p.phoneCountryCode != undefined && p.phone != undefined)
                                    auxPasenger.phone = libphonenumber.parsePhoneNumberFromString("+{0}{1}".format(p.phoneCountryCode, p.phone)).format("INTERNATIONAL").replace(/\s/g, "");

                                if (auxPaxPassportExpireDate != undefined)
                                    auxPasenger.passportExpiry = auxPaxPassportExpireDate.format("YYYY-MM-DD");

                                if (p.baggageByItinerary != null) {
                                    for (var bag in p.baggageByItinerary) {
                                        if (p.baggageByItinerary[bag].quantity > 0) {
                                            auxPasenger.additionalBaggages.push({
                                                itineraryKey: bag,
                                                baggageKey: p.baggageByItinerary[bag].key,
                                                baggageId: p.baggageByItinerary[bag].baggageId
                                            })
                                        }
                                    }
                                }
                                CasinoScoringRQ.passengers.push(auxPasenger);
                            });

                            var selectedSeatsKey = 'selectedSeats_' + CasinoScoringRQ.purchaseId;

                            var sessionSeats = window.sessionStorage.getItem(selectedSeatsKey);
                            if (sessionSeats != null) {
                                var selection = JSON.parse(sessionSeats);

                                angular.forEach(selection, function (segment, key) {

                                    var segmentKey = key;

                                    angular.forEach(selection[key], function (select, key) {

                                        for (var i = 0; i < CasinoScoringRQ.passengers.length; i++) {
                                            if (CasinoScoringRQ.passengers[i].index == select.passenger.index) {
                                                if (CasinoScoringRQ.passengers[i].segmentSeats == null) {
                                                    CasinoScoringRQ.passengers[i].segmentSeats = [];
                                                }
                                                var segementSeat = {
                                                    segmentKey: segmentKey,
                                                    providerKey: select.seat.providerKey,
                                                    seatNumber: select.seat.number
                                                };

                                                CasinoScoringRQ.passengers[i].segmentSeats.push(segementSeat);
                                            }
                                        }
                                    });
                                });
                            }

                            //TDO FFV2: update call to add flexyId & insuranceID

                            $scope.apiClient.PURCHASE.casinoScoring(CasinoScoringRQ).success(function (data) {


                                if (data.status == 0 && data.result == 0) {
                                    $window.FLoading({ vAction: 'hide' });
                                    $scope.bkgdata.banqueCasino = 2; //is good
                                    $scope.bkgdata.banqueCasinoSchedule = data;
                                    $scope.bkgdata.banqueCasinoSchedule.paymentSchedule = data.paymentsSchedules;
                                    $scope.$emit("createBooking", { request: $scope.bookingrequest });
                                }
                                else {
                                    $window.FLoading({ vAction: 'hide' });
                                    $scope.bankCasinoMessage = true;
                                    $scope.bkgdata.banqueCasinoMessage = true;

                                    //$scope.selectPayment('cb');
                                    $("#i-radio-CAS1").prop("checked", false);

                                    $scope.bookingrequest.pspAccountId = undefined;
                                    $scope.bookingrequest.pspId = undefined;
                                    $scope.bookingrequest.paymentMethodId = undefined;

                                    $scope.bkgdata.banqueCasino = 3; //error

                                    $("#pay-banqueCasino").addClass("c-opa-50");
                                    $window.FSmoothPayment({ vClicked: 'cb' });
                                    $("#i-radio-CAS1").attr('disabled', true);
                                }
                            }).error(function (data, code) {
                                $window.FLoading({ vAction: 'hide' });

                                if (code == 412) {
                                    $window.XLConfirm({ vTitle: "Veuillez vérifier les données de formulaire et relancer votre réservation.", vTextYes: "OK", vHideNo: true });
                                }
                                else {
                                    $scope.bankCasinoMessage = true;
                                    $scope.bkgdata.banqueCasinoMessage = true;

                                    $("#i-radio-CAS1").prop("checked", false);

                                    $scope.bookingrequest.pspAccountId = undefined;
                                    $scope.bookingrequest.pspId = undefined;
                                    $scope.bookingrequest.paymentMethodId = undefined;

                                    $scope.bkgdata.banqueCasino = 3; //error

                                    $("#pay-banqueCasino").addClass("c-opa-50");
                                    $window.FSmoothPayment({ vClicked: 'cb' });
                                    $("#i-radio-CAS1").attr('disabled', true);

                                    if (code == 400) {
                                        $window.XLConfirm({
                                            vTitle: "Désolé, votre session est perdue. Merci de bien vouloir relancer une recherche.", vTextYes: "OK", vHideNo: true, fCallBackYes: function () {
                                                $window.location.href = $window.site.loginUrl;
                                            }
                                        });
                                    }
                                    if (code == 410) {
                                        $window.XLConfirm({
                                            vTitle: "Désolé, votre session a expiré. Merci de bien vouloir relancer une recherche.", vTextYes: "OK", vHideNo: true, fCallBackYes: function () {
                                                $window.redoSearch();
                                            }
                                        });
                                    }
                                }
                            });
                        }


            $scope.bkgdata.bookingRequest.fluoSelectedInsurance = { 'insuranceWithFluo': false, 'insurance': null };
            $scope.$on("fluoSelectedInsurance",
                function (event, selectedWithFluo, id) {
                    $scope.bkgdata.bookingRequest.fluoSelectedInsurance = { 'insuranceWithFluo': selectedWithFluo, 'insurance': id };
                    $scope.$apply();
                }
            );

            $scope.bkgdata.bookingRequest.fluoComparison = false;
            $scope.$on("fluoDisplayed",
                function () {
                    $scope.bkgdata.bookingRequest.fluoComparison = true;
                    $scope.$apply();
                }
            );

            $scope.$on('casinoschedule-failed', function () {
                $scope.bankCasinoMessage = true;
	            $scope.bkgdata.banqueCasinoMessage = true;

	            $("#i-radio-CAS1").prop("checked", false);

	            $scope.bookingrequest.pspAccountId = undefined;
	            $scope.bookingrequest.pspId = undefined;
	            $scope.bookingrequest.paymentMethodId = undefined;

	            $scope.bkgdata.banqueCasino = 3; //error

	            $("#pay-banqueCasino").addClass("c-opa-50");
	            $window.FSmoothPayment({ vClicked: 'cb' });
	            $("#i-radio-CAS1").attr('disabled', true);

                console.log('casinoschedule-failed caught');

                $window.FLoading({ vAction: 'hide' });
                $window.mrflyWidgets['airbooking-process-loader-cb4x'].$store.commit("hideLoader");

                $scope.$apply();
            });

            $scope.report = function (paxIndex) {

                $scope.buyer.firstName = $scope.paxinfo[paxIndex].firstName;
                $scope.buyer.lastName = $scope.paxinfo[paxIndex].lastName;
                $scope.buyer.title = $scope.paxinfo[paxIndex].title;
                $scope.bookingrequest.bankCasino.birthDate = $scope.paxinfo[paxIndex].birthDate;

                //Traveller Phone Number
                if (paxIndex === 0) {
                    if ($scope.paxinfo[paxIndex].phoneCountryCode && $scope.paxinfo[paxIndex].phone) {
                        $scope.contact.telCountryCode = $scope.paxinfo[paxIndex].phoneCountryCode;
                        $scope.contact.telNumber = $scope.paxinfo[paxIndex].phone;
                    }
                }
                else {
                    $scope.contact.telCountryCode = "33";
                    $scope.contact.telNumber = "";
                }

                if ($scope.contact.telCountryCode) {
                    if (countriesCodeKey[$scope.contact.telCountryCode].length > 0)
                        $scope.countryCode = countriesCodeKey[$scope.contact.telCountryCode][0].iso;
                    else
                        $scope.countryCode = countriesCodeKey[$scope.contact.telCountryCode].iso;
                }
            };

            //Params (has to be done after 'report' delcaration)
            var params = $window.getUrlVars();

            if ($scope.contact.mail && params['email']) {
                $scope.contact.mail = decodeURIComponent(params['email']);
                $scope.report(0);//select 1st pax
                $('#i-payer-text').text($scope.paxes[0]);
                $('#i-payer-text-label').addClass('c-standby');
            }

            $scope.isValidForNextStep = function () {
                var missing = 0;
                var buyer = $scope.buyer;
                var contact = $scope.contact;

                if (typeof buyer.firstName == 'undefined' || buyer.firstName == null || buyer.firstName == "") missing = 1;
                if (typeof buyer.lastName == 'undefined' || buyer.lastName == null || buyer.lastName == "") missing = 2;
                if (typeof buyer.title == 'undefined' || buyer.title == null || buyer.title == "") missing = 3;
                if (typeof buyer.address == 'undefined' || buyer.address == null || buyer.address == "") missing = 4;
                if (typeof buyer.city == 'undefined' || buyer.city == null || buyer.city == "") missing = 5;
                if (typeof buyer.country == 'undefined' || buyer.country == null || buyer.country == "") missing = 6;
                if (typeof contact.mail == 'undefined' || contact.mail == null || contact.mail == "") missing = 7;
                if (typeof buyer.zipCode == 'undefined' || buyer.zipCode == null || buyer.zipCode == "") missing = 8;
                if (typeof contact.telCountryCode == 'undefined' || contact.telCountryCode == null || contact.telCountryCode == "") missing = 9;
                if (typeof contact.telNumber == 'undefined' || contact.telNumber == null || contact.telNumber == "") missing = 10;

                if (typeof contact.mail != 'undefined' && contact.mail != null && contact.mail != "") {
                    $window.sendInspectlet('tagSession', { Email: contact.mail });
                }

                console.log("missing:", missing);
                return missing == 0;
            };

            $scope.callDrop = function (_event, type) {
                var varIsCountry = (type == 'country') ? 1 : 0;
                var name = 'i-' + type;
                var value = $("#ul-" + type).get(0);
                $window.FSelect({ e: _event, myObj: value, vName: name, isCountry: varIsCountry });
            };

            $scope.$watch('bookingrequest.bankCasino.departmentOfBirth', function () {
                $scope.bookingrequest.bankCasino.departmentOfBirth = $scope.bookingrequest.bankCasino.departmentOfBirth.replace(/[A-z]/, "");
            }, true);



            $scope.selectPayment = function (name, id, selectedPayment) {
                //Add InspecLet Payment tag
                window.sendInspectlet('tagSession', { PaymentMethod: name });
                if ($scope.bkgdata.banqueCasino == 1 || $scope.bkgdata.banqueCasino == 2 && !$scope.bkgdata.banqueCasinoMessage) {
                    $window.FSmoothPayment({ vClicked: name });
                }
                $scope.cb4xSelected = false;
                $scope.paypal = false;
                if (name == "4x") {
                    FSmoothOpen({ iSmooth: 'i-smooth-payment-4x', vForce: 'open' });
                    $scope.cb4xSelected = true;
                }
                else {
                    FSmoothOpen({ iSmooth: 'i-smooth-payment-4x', vForce: 'close' });
                    $scope.cb4xSelected = false;
                }
                if (selectedPayment == "CARREFOURPASS") {
                    FSmoothOpen({ iSmooth: 'i-smooth-passpayment', vForce: 'open' });
                }
                else {
                    FSmoothOpen({ iSmooth: 'i-smooth-passpayment', vForce: 'close' });
                }
                //if (name == "paypal") {
                //    FSmoothOpen({ iSmooth: 'i-smooth-payment-4x', vForce: 'open' });
                //    $scope.paypal = true;
                //}
                //else {
                //    FSmoothOpen({ iSmooth: 'i-smooth-payment-4x', vForce: 'close' });
                //    $scope.paypal = false;
                //}
                //mrflyWidgets["mrfly-widget-disclaimers"].cb4xSelected = $scope.cb4xSelected;
                //mrflyWidgets["mrfly-widget-disclaimers"].paypal = $scope.paypal;
                $scope.bookingrequest.pspAccountId = id.pspAccountId;
                $scope.bookingrequest.pspId = id.pspId;

                $scope.bookingrequest.paymentMethodId = id.paymentMethodId;
                $scope.bookingrequest.paymentMethod = id;

                if (selectedPayment)
                    $scope.bookingrequest.paymentMethod.paymentType = selectedPayment;

                $scope.bkgdata.selectedPayment = selectedPayment;
            };

            //usual discount process
            $scope.validateDiscountCode = function () {
                try {
                    var flexyId = $window.sessionStorage.getItem("inssurance.flexy.selectedOffer");
                    var requestId = $window.sessionStorage.getItem("inssurance.flexyRequest.selectedOffer");
                    if (flexyId != "null" && requestId != "null" && $scope.bkgdata.request.requestId == requestId && $scope.bkgdata.bookingRequest.flexyId != flexyId) {
                        $scope.bkgdata.bookingRequest.flexyId = flexyId;
                    }
                }
                catch (error) {
                    console.log(error);
                }

                var discountCodeRq = {
                    requestId: $scope.bkgdata.purchaseRequest.requestId,
                    purchaseId: $scope.bkgdata.purchaseResponse.purchaseId,
                    flexyId: $scope.bkgdata.bookingRequest.flexyId,
                    index: $scope.bkgdata.purchaseResponse.proposalsRequested.index,
                    insuranceId: $scope.bkgdata.bookingRequest.insurance != null ? $scope.bkgdata.bookingRequest.insurance.id : null,
                    discountCode: $scope.bookingrequest.discountCode.toUpperCase(),
                    IsCdav: $window.sessionStorage.getItem("hasCdav") == "true" ? true : false,
                    passengers: []
                };

                //Procesar maletas adicionales por pasajero
                $scope.bkgdata.bookingRequest.passengers.each(function (p) {
                    var auxPaxPassportExpireDate = null;
                    var auxPaxBirthDate = moment(p.birthDate, "DD-MM-YYYY");

                    if (p.passportExpireDate != undefined)
                        auxPaxPassportExpireDate = moment(p.passportExpireDate, "DD-MM-YYYY");

                    var auxPasenger = {
                        firstName: p.firstName,
                        lastName: p.lastName,
                        birthDate: auxPaxBirthDate.format("YYYY-MM-DD"),
                        index: p.index,
                        type: p.paxType,
                        phoneCountryCode: p.phoneCountryCode,
                        ptc: p.ptc,
                        title: p.title === "1" ? 1 : p.title === "2" ? 2 : 0,
                        passportNumber: p.passportNumber,
                        passportCountry: p.passportCountry,
                        additionalBaggages: [],
                        fidelityCardNumber: p.fidelityCardNumber,
                        fidelityCard: p.fidelityCardType
                    }

                    if (p.phoneCountryCode != undefined && p.phone != undefined && p.phoneCountryCode != '' && p.phone != '') {
                        auxPasenger.phone = libphonenumber.parsePhoneNumberFromString("+{0}{1}".format(p.phoneCountryCode, p.phone)).format("INTERNATIONAL").replace(/\s/g, "");
                    }

                    if (auxPaxPassportExpireDate != undefined)
                        auxPasenger.passportExpiry = auxPaxPassportExpireDate.format("YYYY-MM-DD");

                    if (p.baggageByItinerary != null) {
                        for (var bag in p.baggageByItinerary) {
                            if (p.baggageByItinerary.hasOwnProperty(bag)) {
                                if (p.baggageByItinerary[bag].quantity > 0) {
                                    auxPasenger.additionalBaggages.push({
                                        itineraryKey: bag,
                                        baggageKey: p.baggageByItinerary[bag].key,
                                        baggageId: p.baggageByItinerary[bag].baggageId
                                    });
                                }
                            }
                        }
                    }
                    discountCodeRq.passengers.push(auxPasenger);
                });

                var selectedSeatsKey = 'selectedSeats_' + discountCodeRq.purchaseId;
                var sessionSeats = window.sessionStorage.getItem(selectedSeatsKey);
                if (sessionSeats != null) {
                    var selection = JSON.parse(sessionSeats);

                    angular.forEach(selection, function (segment, key) {

                        var segmentKey = key;

                        angular.forEach(selection[key], function (select, key) {

                            for (var i = 0; i < discountCodeRq.passengers.length; i++) {
                                if (discountCodeRq.passengers[i].index == select.passenger.index) {
                                    if (discountCodeRq.passengers[i].segmentSeats == null) {
                                        discountCodeRq.passengers[i].segmentSeats = [];
                                    }
                                    var segementSeat = {
                                        segmentKey: segmentKey,
                                        providerKey: select.seat.providerKey,
                                        seatNumber: select.seat.number
                                    };

                                    discountCodeRq.passengers[i].segmentSeats.push(segementSeat);
                                }
                            }
                        });
                    });
                }

                //Add contact data
                var missingContact = false;
                if (typeof $scope.contact.mail == 'undefined' || $scope.contact.mail == null || $scope.contact.mail == "") {
                    missingContact = true;
                }
                if (typeof $scope.contact.emailConfirm == 'undefined' || $scope.contact.emailConfirm == null || $scope.contact.emailConfirm == "") {
                    missingContact = true;
                }
                if (typeof $scope.contact.telNumber == 'undefined' || $scope.contact.telNumber == null || $scope.contact.telNumber == "") {
                    missingContact = true;
                }
                        
                if (!missingContact) {
                    discountCodeRq.contact = {
                        mail: $scope.contact.mail,
                        phone: libphonenumber.parsePhoneNumberFromString("+{0}{1}".format($scope.contact.telCountryCode, $scope.contact.telNumber)).format("INTERNATIONAL").replace(/\s/g, "")
                    }
                }

                if (missingContact === false) {
                    $scope.apiClient.PURCHASE.checkDiscount(discountCodeRq).success(function (data) {
                        if (data.status === 0) {
                            $scope.bkgdata.discountInfo = data;
                            $scope.bkgdata.discountInfo.discountCode = discountCodeRq.discountCode;
                            if (data.discountMessage != null) {
                                $scope.bkgdata.discountInfo.errorMessage = data.discountMessage;
                                $scope.bookingrequest.discountCode = null;
                                $window.FSellitTog('i-promo-0', 0);
                                $("#i-pport-3").removeAttr("disabled");
                            }
                            else {
                                //insert the code in addionalData only if  $scope.bookingrequest.discountCode is valid                           
                                $scope.bkgdata.discountInfo.applicableAmount *= -1;
                                $scope.bkgdata.discountInfo.errorMessage = "";
                                $("#i-pport-3").attr("disabled", "");
                                $scope.$emit("getBanqueCasinoSchedule");
                                $scope.$emit("updateTotal");
                            }
                        }
                        else {
                            $scope.bkgdata.discountInfo = null;
                            $scope.bookingrequest.discountCode = null;
                            FSellitTog('i-promo-0', 0);
                        }
                        $scope.bkgdata.validDiscountCode = $scope.bookingrequest.discountCode;
                    }).error(function (code) {
                        $window.FSellitTog('i-promo-0', 0);
                        if (code === 400) {
                            $window.XLConfirm({
                                vTitle: "Désolé, votre session est perdue. Merci de bien vouloir relancer une recherche.", vTextYes: "OK", vHideNo: true, fCallBackYes: function () {
                                    $window.location.href = $window.site.loginUrl;
                                }
                            });
                        }
                        if (code === 410) {
                            $window.XLConfirm({
                                vTitle: "Désolé, votre session a expiré. Merci de bien vouloir relancer une recherche.", vTextYes: "OK", vHideNo: true, fCallBackYes: function () {
                                    $window.redoSearch();
                                }
                            });
                        }
                        if (code === 412) {
                            $window.XLConfirm({ vTitle: "Veuillez vérifier les données de formulaire et relancer votre réservation.", vTextYes: "OK", vHideNo: true });
                        }
                    });
                }
                else {
                    //display error message & reset button
                    $window.FSellitTog('i-promo-0', 0);
                }
            }

            //TODO FFV2: update call to add flexyId & insuranceId
            $scope.checkDiscountCode = function (enable) {
                //Add InspecLet Use of Discount code tag
                window.sendInspectlet('tagSession', { DiscountCodeUse: true });

                $scope.reactivateDiscountError = false;
                $scope.reactivateDiscountMessage = false;
                $scope.fakeDiscountCode = null;

                if (enable && $scope.bookingrequest.discountCode !== "" && 
                    $scope.bookingrequest.discountCode !== null && 
                    $scope.bookingrequest.discountCode !== undefined) {

                    var regex = RegExp('^(FF|TF)', 'i'); //start with FF or TF (case insensitive)
                    var checkRequired = regex.test($scope.bookingrequest.discountCode.trim());

                    if (checkRequired) { //call lemmings for matching patterns only
                        $window.FLoading({ vAction: 'show' });

                        $http.post('/api/api/discountcode/checkreactivate', {
                            discountCode: $scope.bookingrequest.discountCode,
                        }).then(function (response) {

                            $window.FLoading({ vAction: 'hide' });

                            if (response.data === true) {
                                //display message then reset code and buton state
                                $scope.reactivateDiscountMessage = true;
                                $scope.bkgdata.discountInfo = null;
                                $scope.bookingrequest.discountCode = null; //don't send discount to createbooking !
                                FSellitTog('i-promo-0', 0);
                            }
                            else {//usual process

                                setTimeout($scope.validateDiscountCode(), 500);
                            }
                        },
                        function () {//error => reset code and buton state

                            $window.FLoading({ vAction: 'hide' });
                            $scope.reactivateDiscountError = true;
                            $scope.bkgdata.discountInfo = null;
                            $scope.bookingrequest.discountCode = null;
                            FSellitTog('i-promo-0', 0);
                        });
                    }
                    else {//usual process
                        setTimeout($scope.validateDiscountCode(), 500);
                    }                    
                }
                else {
                    $scope.bkgdata.discountInfo = null;
                    $scope.bookingrequest.discountCode = null;
                    FSellitTog('i-promo-0', 0);
                    $("#i-pport-3").removeAttr("disabled");
                    $scope.$emit("getBanqueCasinoSchedule");
                    $scope.$emit("updateTotal");
                }
            };

            if ($scope.bookingrequest.discountCode != "" && $scope.bookingrequest.discountCode != null && $scope.bookingrequest.discountCode != undefined) {
                $timeout(function () { $window.FSellitTog('i-promo-0', 1); }, 500);
                $scope.checkDiscountCode(true);
            }
            else {
                if (typeof $scope.bkgdata.discountInfo !== 'undefined') {
                    $scope.bkgdata.discountInfo.errorMessage = null;
                }
            }

            $scope.$on("getBanqueCasinoScheduleCallback", function (event, args) {
                $scope.prepareBanqueCasinoData();
            });

            $timeout(function () {
                if ($window.isAirbus) $window.XLFormInit({ iCont: 'airbus-payment-form' });
                $window.XLFormInit({ iCont: 'i-form' });
                $window.XLFormInit({ iCont: 'i-form-payment' });
                if ($scope.showCEBlock)
                    $window.XLFormInit({ iCont: 'i-form-ce' });
                $window.FCountriesFlags({ iUl: 'i-phone-codes-ul' });
                $window.FCountriesFlags({ iUl: 'i-country-ul' });
                $('#id-country-div').addClass('c-new-field-ok');
                //$('#id-phone-codes').addClass('c-field-ok');
            }, 500);

            window.innerScope = $scope;

            //Cheques Vacances
            $scope.ancvCheckClick = function () {

                //Add InspecLet ChequesVacances tag
                window.sendInspectlet('tagSession', { ChequesVacances: true });

                if ($scope.bookingrequest && $scope.bookingrequest.holidayVoucher) {
                    $scope.bookingrequest.holidayVoucher = false;
                }
                else {
                    if ($scope.bookingrequest) {
                        $scope.bookingrequest.holidayVoucher = true;
                    }
                }
                /*var opener = $('#i-check-f1')[0];
                $window.FSmoothOpen({ oOpener: opener, iSmooth: 'i-smooth-payment-ancv' });*/
                FSmoothOpen({ iSmooth: 'i-smooth-payment-ancv' });
            }

            //$window.sendXiti("tunnel_resavol::infos_payeur_resavol", 1);
            setTimeout(function () {
                document.getElementById('switcher-bd').style.width = document.getElementById('connect').offsetWidth * 2 + 'px';
                document.getElementById('classic').style.width = document.getElementById('connect').offsetWidth + 'px';
                document.getElementById('switcher-overlay').style.width = document.getElementById('connect').offsetWidth + 'px';

                window.scrollTo(0, $($("#i-purchase-123")).offset().top - 15);
            }, 100);
            console.log($scope.isloggedin);
            /// if there is autocomplete 
            if ($scope.isloggedin === true && $scope.buyer && $scope.buyer.firstName && $scope.buyer.lastName) {
                //$scope.report(0);
                var name = $scope.buyer.firstName + " " + $scope.buyer.lastName;
                myEl = angular.element(document.querySelector('#i-payer-text'));
                myEl.text(name);
            }
            if (window.site.partnerId == 'cdiscountvol') $scope.checkCGV = true;
            //getBundleWidget("mrfly-widget-disclaimers.js").done(function () {
            //    mrflyWidgets["mrfly-widget-disclaimers"].isairbus = $window.isAirbus;
            //    mrflyWidgets["mrfly-widget-disclaimers"].purchaseResponse = $scope.bkgdata.purchaseResponse;
            //    mrflyWidgets["mrfly-widget-disclaimers"].checkCGV = $scope.checkCGV;
            //    mrflyWidgets["mrfly-widget-disclaimers"].isCasinoPayment = $scope.isCasinoPayment;
            //    mrflyWidgets["mrfly-widget-disclaimers"].cb4xSelected = $scope.cb4xSelected;
            //    mrflyWidgets["mrfly-widget-disclaimers"].lng = $scope.lng;

            //}).fail(function (jqxhr, settings, exception) {
            //    console.log(exception);
            //    });

        };
        return directive;
    }]);
