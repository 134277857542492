webClient.controller('FlightBookingRecap', ['$scope', '$window', '$timeout', '$location', '$i18next', '$http', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'bookingRequest', 'insurancesSelected', 'bookingRequestKey', 'insuranceOnce', 'purchaseResponse', 'trackingService', 'gtmProduct', 'userInfoResult', 'additionalData', 'omnitureService',
    function ($scope, $window, $timeout, $location, $i18next, $http, tseApiClient, tseUtil, airHelper, bookingRequest, insurancesSelected, bookingRequestKey, insuranceOnce, purchaseResponse, trackingService, gtmProduct, userInfoResult, additionalData, omnitureService) {
        $scope.accommodationIsReady = false;
        //Initialize Variables
        $scope.getText = function (key) { return window.culture.texts[key]; };
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        var searchUrl = $window.site.currentAirRequest.searchUrl + "?" + tseUtil.Location.getQueryString();
        var user = window.site;
        var existWonderboxCode = user && user.loginInfo && user.loginInfo.Code != null && user.loginInfo.Code != undefined;
        $scope.shouldAutoFill = !(user.isLogged && user.type == 2) ||
            parseInt(new URLSearchParams(tseUtil.Location.getQueryString()).get('autofillInfo')) == 1 ? true : false;
        $scope.siteType = $window.site.type;
        $scope.requestSummary = $window.site.currentAirRequest.summary;
        $scope.requestSummary = $window.site.currentAirRequest.summary;
        $scope.searchForm = {};
        $scope.initialPriceValue = 0;
        $scope.searchForm.type = $scope.requestSummary.roundTrip ? 0 : $scope.requestSummary.oneWay ? 1 : 2;
        $scope.searchForm.cabin = $scope.requestSummary.cabinClass;
        $scope.searchForm.origin = $scope.requestSummary.origin.name;
        $scope.searchForm.origin_value = $scope.requestSummary.origin.value;
        $scope.searchForm.destination = $scope.requestSummary.destination.name;
        $scope.searchForm.destination_value = $scope.requestSummary.destination.value;
        $scope.searchForm.goDate = $scope.requestSummary.goDate.dateString;
        $scope.searchForm.adults = $scope.requestSummary.adults;
        $scope.searchForm.childrens = $scope.requestSummary.childs;
        $scope.searchForm.infants = $scope.requestSummary.infants;
        $scope.siteIsB2b = user.isLogged && user.type == 2;
        $scope.bkgDataKey = "";
        $scope.recapTitle = $window.site.loginInfo.Client.CompanyName;
        $scope.currentBkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
        $scope.assistanceRequests = null;
        $scope.hasFlightHotelFormSummary = hasCookie("FlightHotelFormSummary") && $window.getUrlVars()['hotelSummary'] === 'true';

        if (typeof $window.marketCode === "undefined") {
            $scope.marketCode = '';
        } else {
            $scope.marketCode = $window.marketCode.toUpperCase();
        }
        $scope.isUpsell = $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';

        $window.$scope = $scope;
        $scope.enableFreeFlex = $window.enableFreeFlex === true;
        $scope.selectDocumentsLanguage = lng.substring(0, 2).toUpperCase();
        //Flags
        $scope.enablePaypal = $window.enablePaypal;
        $scope.enableBrandedFares = $window.enableBrandedFares;
        $scope.enableSendParameters = $window.enableSendParameters;
        if ($window.hideInsurance) $scope.hideInsurance = true;
        else $scope.hideInsurance = false;
        if ($window.isAirbus) $scope.isAirbus = $window.isAirbus;

        //block navgation if mismatch beetween paxtypes from hotel and flight
        $scope.invalidPaxTypes = false;

        //never show flexy when searching flight + hotel
        $scope.hideFlexy = $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';
        var siteTypeIsB2b = $scope.siteType === 2;

        getBundleWidget("mrfly-widget-loader.js").done(function () {
        }).fail(function (jqxhr, settings, exception) {
            console.log(exception);
        });
        
        $scope.printDisabled = true;
        window.onload = function () {
            const city_year = $scope.bkgData.request.destination.cityName + ' ' + moment($scope.bkgData.request.goDate.date).format('MMMM YYYY');
            const partnerName = window.site.loginInfo.PartnerName;
            document.title = getT('bookingrecap.favicontitle').replace('$city_year', city_year).replace('$partner', partnerName)
            setDocumentReady();
        }
        let cptPrint = 0;
        function setDocumentReady() {
            if (cptPrint > 5) {
                return;
            }
            if (document.readyState == 'complete') {
                $scope.printDisabled = false;
            } else {
                setTimeout(function () {
                    setDocumentReady()
                }, 500);
            }
        }

        $window.FLoading({ vAction: 'show' });
        $(".c-footer").addClass("d-none");

        //save url for meta search back custom behaviour
        window.sessionStorage.setItem('currentAirRequestresultsUrl', window.site.currentAirRequest.resultsUrl);

        try {


        } catch (ex) {

        }
        //Get data from session storage

        var bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);

        var setSearchForm = function () {
            var currentSearch = $scope.searchForm;
            currentSearch.key = $window.flightHistory.createKey(currentSearch);
            currentSearch.requestId = $scope.requestSummary.requestId;
            var referrer = document.referrer;

            var lastSearch = $window.flightHistory.loadLastSearch();
            if (lastSearch && !/airprebook/i.test(referrer)) {
                console.log("LASTSEARCH FOUND");
                console.log(lastSearch);
                if (lastSearch.origin_value == currentSearch.origin_value)
                    currentSearch.origin = lastSearch.origin;
                if (lastSearch.destination_value == currentSearch.destination_value)
                    currentSearch.destination = lastSearch.destination;
                if (lastSearch.origin2_value == currentSearch.origin2_value)
                    currentSearch.origin2 = lastSearch.origin2;
                if (lastSearch.destination2_value == currentSearch.destination2_value)
                    currentSearch.destination2 = lastSearch.destination2;
                $window.flightHistory.bindView(lastSearch);
                //$window.flightHistory.setPaxView(currentSearch.adults, currentSearch.childrens, currentSearch.infants);
            }
            else {
                console.log("LASTSEARCH NOT FOUND");
                //$window.flightHistory.bindView(currentSearch);
                $window.flightHistory.loadFromMetaSearch($scope.bkgData.request);
            }
            $window.flightHistory.setPaxView(currentSearch.adults, currentSearch.childrens, currentSearch.infants);
            console.log("CURRENTSEARCH IS");
            console.log(currentSearch);
            if (window.site.partnerId === "servair" || window.site.partnerId === "cnas" || window.site.partnerId === "cnasdomtom") {
                if (!window.mrflyWidgets["mrfly-widget-search-engine"]) {
                    if ($('#search-engine-widget').length > 0) {
                        getBundleWidget("mrfly-widget-search-engine.js");

                    }
                }

            }

            //$window.flightHistory.bindView(currentSearch);
            //$window.flightHistory.bindView(lastSearch);
            //$window.flightHistory.setPaxView(currentSearch.adults, currentSearch.childrens, currentSearch.infants);
            // $(".c-search input[name='adults']").val(currentSearch.adults);
            // $(".c-search  input[name='childs']").val(currentSearch.childrens);
            // $(".c-search input[name='infants']").val(currentSearch.infants);
        };





        var purchaseNew = function (data, adults, childs, infants) {
            var request_id = data.RequestId.split('-').join('');
            var _adults = parseInt(adults);
            var _childs = parseInt(childs);
            var _infants = parseInt(infants);
            var rawUrl = "";
            $scope.requestSummary.requestId = request_id;
            $scope.requestSummary.adults = _adults;
            $scope.requestSummary.childs = _childs;
            $scope.requestSummary.infants = _infants;
            $scope.requestSummary.totalPaxes = _adults + _childs + _infants;
            $scope.bkgDataKey = 'bkgData_' + $scope.requestSummary.requestId;


            var _bookingUrl = $window.site.currentAirRequest.bookingUrl;
            var _paymentUrl = $window.site.currentAirRequest.paymentUrl;
            var _upsellProducts = $window.site.currentAirRequest.upsellProducts;
            var _upsellProductsRedirect = $window.site.currentAirRequest.upsellProductsRedirect;
            var _regex = /[a-f0-9]{8}(?:[a-f0-9]{4}){3}[a-f0-9]{12}/;
            if (_bookingUrl.match(_regex)) {
                var result = _bookingUrl.replace(_regex, request_id);
                $window.site.currentAirRequest.bookingUrl = result;
            }

            if (_paymentUrl.match(_regex)) {
                var result = _paymentUrl.replace(_regex, request_id);
                $window.site.currentAirRequest.paymentUrl = result;
            }

            if (_upsellProducts.match(_regex)) {
                var result = _upsellProducts.replace(_regex, request_id);
                $window.site.currentAirRequest.upsellProducts = result;
            }

            if (_upsellProductsRedirect.match(_regex)) {
                var result = _upsellProductsRedirect.replace(_regex, request_id);
                $window.site.currentAirRequest.upsellProductsRedirect = result;
            }

            var bookingData = {
                key: $scope.bkgDataKey,
                value: {
                    purchaseRequest: data.PurchaseRQ,
                    outboundKey: data.Outbound.Key,
                    request: $scope.requestSummary,
                    cabinclasses: $scope.requestSummary.cabinClass
                }
            };

            if (data.Inbound != null) {
                bookingData.value.inboundKey = data.Inbound.Key;
            }

            var upsellParam = $window.getUrlVars()['upsell'];
            var isUpsell = typeof upsellParam !== 'undefined' && upsellParam === 'true';

            if ($window.site.onSecureScheme) {
                tseUtil.Session.set(bookingData.key, bookingData.value);

                if ($window.enableUpSellProducts && $scope.requestSummary.roundTrip) {
                    $window.location.href = $window.site.currentAirRequest.upsellProducts;
                }
                else if (isUpsell) {

                    var bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);

                    if (bkgData) {

                        var purchaseResponse = { value: "" };

                        //New Purchase Transaction
                        if (data && data.message == null) {


                            if (data.proposals[0].flexies && data.proposals[0].flexies.length > 0) {
                                $scope.enableFreeFlex = data.proposals[0].flexies[0].propose === 2;
                            }

                            airHelper.preparePurchaseData(data);
                            bkgData.purchaseResponse = data;

                            purchaseResponse.value = data;
                            bkgData.severalProposals = bkgData.purchaseResponse.proposals.length > 1;
                            $scope.lastDate = bkgData.purchaseResponse.itineraries[bkgData.purchaseResponse.itineraries.length - 1].arrivalDateTime;

                            keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
                            tseUtil.Session.set("purchaseResponse_" + keyGenerated, data);

                            var keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
                            console.log(keyGenerated);

                            // BookingRequest  Object to send to API
                            bkgData.bookingRequest = {
                                proposalIndex: "",
                                bagChanged: false,
                                requestId: bkgData.request.requestId,
                                flexyFare: false,
                                insurance: null,
                                passengers: [],
                                additionalData: [],
                                buyer: {},
                                contact: { mail: '', phone: '' },
                                bestPriceFare: false
                            };

                            //Pax Init
                            var paxAmount = [bkgData.request.adults, bkgData.request.childs, bkgData.request.infants];
                            keyGenerated = "";
                            var init = true;

                            for (var ptc in paxAmount) {
                                if (paxAmount.hasOwnProperty(ptc)) {
                                    for (var i = 0; i < paxAmount[ptc]; i++) {
                                        bkgData.bookingRequest.passengers.push({ paxType: ptc });
                                    }
                                }
                            }

                            if (init) {
                                airHelper.preparePassengersData(bkgData.bookingRequest.passengers, bkgData.purchaseResponse);
                            }

                            var upsellProductsRedirect = $window.site.currentAirRequest.upsellProductsRedirect.replace(/&code=\d/i, null);
                            var redirectUrl = $window.hotelUpSellProductRedirect;

                            //avoid double slash in the url
                            if (/\/$/.test(redirectUrl) === false) {
                                redirectUrl += "/";
                            }

                            redirectUrl += "redirect.htm?purchaseId=" + bkgData.purchaseResponse.purchaseId + "&requestId=" + bkgData.request.requestId + "&redirect=" + encodeURIComponent(upsellProductsRedirect);

                            //B2B
                            if ($window.site.type === 2) {
                                redirectUrl += "&mid=" + $window.site.loginInfo.Agent.AgentId;
                                redirectUrl += "&lid=" + $window.site.loginInfo.LoginId;
                            }

                            //HOTEL TIMER: save koedia redirection time to init timer later
                            var timerkey = "hoteltimerstart_" + bkgData.purchaseResponse.purchaseId;
                            $window.sessionStorage.setItem(timerkey, new Date());

                            //$window.location.href = redirectUrl;
                            rawUrl = redirectUrl;
                            //return finalUrl;
                        }
                        else {
                            //$window.location.href = $window.site.errorsUrls.GeneralError;
                            // return $window.site.errorsUrls.GeneralError;
                            rawUrl = $window.site.errorsUrls.GeneralError;
                        }
                    }
                }
                else {
                    //$window.location.href = $window.site.currentAirRequest.bookingUrl;
                    //return $window.site.currentAirRequest.bookingUrl;
                    rawUrl = $window.site.currentAirRequest.bookingUrl;

                }
            }
            else {
                $window.sslFrame.sendCmd("setSessionStorage", bookingData,
                    function (data) {
                        tseUtil.Debug.log("BookingSession created!");
                        if ((window.enableUpSellProducts || isUpsell) && $scope.requestSummary.roundTrip) {
                            //$window.location.href = $window.site.currentAirRequest.upsellProducts;
                            // return $window.site.currentAirRequest.upsellProducts;                            
                            rawUrl = $window.site.currentAirRequest.upsellProducts;
                        } else {
                            //$window.location.href = $window.site.currentAirRequest.bookingUrl;
                            // return $window.site.currentAirRequest.bookingUrl;
                            rawUrl = $window.site.currentAirRequest.bookingUrl;

                        }
                    });
            }

            var url = new URL(rawUrl);
            var query_string = url.search;
            var search_params = new URLSearchParams(query_string);
            search_params.set('adults', _adults);
            search_params.set('childs', _childs);
            search_params.set('infants', _infants);
            url.search = search_params.toString();
            var new_url = url.toString();
            return new_url;
        };

        var getBasketDetails = function () {
            var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
            var mandatoryFees = [];
            var totalAccommodations = siteTypeIsB2b ? $scope.bkgData.agencyMarginHotel.value : 0;
            $scope.bkgData.accommodations.forEach(function (accommodation) {
                totalAccommodations += siteTypeIsB2b ? accommodation.totalPriceRacknet : accommodation.totalPriceForcedPrice;
            });
            //Add pax detail


            mandatoryFees.push({
                flexyDescription: getT('airbooking.flightprice'),
                totalPrice: $scope.bkgData.prixTotal.value,
                prices: []
            });
            mandatoryFees.push({
                flexyDescription: getT('mrfly-widget-accommodation-info.hotelprice'),
                totalPrice: totalAccommodations,
                prices: []
            });
            if (proposal.mandatoryFees) {
                proposal.mandatoryFees.forEach(function (mandatoryFee) {
                    mandatoryFee.flexyDescription = getT('mrfly-widget-basket.bookingfees');
                });
                Array.prototype.push.apply(mandatoryFees, proposal.mandatoryFees);
            }
            return mandatoryFees;
        }

        $scope.processShopBot = function (data, adults, childs, infants) {
            $scope.bkgData.purchaseResponse.adult = adults;
            $scope.bkgData.purchaseResponse.child = childs;
            $scope.bkgData.purchaseResponse.infant = infants;
            $scope.$apply();
            if (data.PurchaseRS != null) {
                $scope.bkgData.prixTotal.int = data.PurchaseRS.Proposals[0].TotalAmount.Integer;
                $scope.bkgData.prixTotal.dec = data.PurchaseRS.Proposals[0].TotalAmount.Decimal;
                $scope.loader.$store.commit("hideLoader");
                var url = purchaseNew(data, adults, childs, infants);
                $scope.$apply();
                $('[data-remodal-id="modal-proposal-available"]').addClass("remodal");
                $('[data-remodal-id="modal-proposal-available"]').remodal({ hashTracking: false }).open();
                $(document).on('confirmation', '[data-remodal-id="modal-proposal-available"]', function () {
                    console.log('Confirmation remodal available');
                    $window.location.href = url;
                });
            }
            else {
                $scope.loader.$store.commit("hideLoader");
                $('[data-remodal-id="modal-proposal-notavailable"]').addClass("remodal");
                $('[data-remodal-id="modal-proposal-notavailable"]').remodal({ hashTracking: false }).open();
                $(document).on('cancellation', '[data-remodal-id="modal-proposal-notavailable"]', function () {
                    console.log('Confirmation remodal notavailable');
                    if (!document.getElementById("i-search").hasAttribute("action")) {
                        document.getElementById("i-search").setAttribute("action", "/airsearch");
                    }
                    document.getElementById('i-search').submit();
                });
                $(document).on('confirmation', '[data-remodal-id="modal-proposal-notavailable"]', function () {
                    console.log("onGoBackOnSelection");
                    $window.location.reload();
                });
            }
        };

        var resumeSearch = function () {
            var btn = document.getElementById("i-search-btn");

            if (btn != null) {
                btn.removeAttribute("onclick");
                $scope.loader = mrflyWidgets['shopbot-waiting-loader'];
                //btn.setAttribute("onclick","");
                //$("#i-search-btn")[0].onclick = null;
                $("#i-search-btn").click(function () {

                    var paxvalue = document.getElementById("paxsearch");
                    var genuinevalue = document.getElementById("genuinesearch");
                    if (paxvalue == null && genuinevalue == null) {
                        if (!document.getElementById("i-search").hasAttribute("action")) {
                            document.getElementById("i-search").setAttribute("action", "/airsearch");
                        }
                        document.getElementById('i-search').submit();
                    }
                    else if ((genuinevalue.value === "true") || ((genuinevalue.value === "false") && (paxvalue.value === "false"))) {
                        if (!document.getElementById("i-search").hasAttribute("action")) {
                            document.getElementById("i-search").setAttribute("action", "/airsearch");
                        }
                        document.getElementById('i-search').submit();
                    }
                    else {
                        var origin = document.getElementsByName("origin")[0].value;
                        var destination = document.getElementsByName("destination")[0].value
                        var vAdults = document.getElementsByName('adults')[0].value;
                        var vChilds = document.getElementsByName('childs')[0].value;
                        var vInfants = document.getElementsByName('infants')[0].value;
                        var apiToken = getCookie("authToken_" + window.site.partnerId);
                        var vGoDate = document.getElementsByName('goDate')[0].value;
                        var cabinClass = document.getElementsByName('cabinClass')[0].value;
                        var vReturnDate = document.getElementsByName('returnDate')[0].value;
                        document.getElementById("i-search").removeAttribute("action");
                        var outboundKey = $scope.currentBkgData.outboundKey;
                        var inboundKey = "";
                        if ($scope.currentBkgData.inboundKey != null) {
                            inboundKey = $scope.currentBkgData.inboundKey;
                        }
                        $scope.loader.$store.commit("showLoader");
                        $.ajax({
                            url: '/shopbot/resume',
                            type: 'get',
                            data: {
                                origin: origin,
                                token: apiToken,
                                destination: destination,
                                adults: vAdults,
                                childs: vChilds,
                                infants: vInfants,
                                godate: vGoDate,
                                returndate: vReturnDate,
                                currentInboundKey: inboundKey,
                                currentOutboundKey: outboundKey,
                                cabinclass: cabinClass
                            },
                            success: function (data) {
                                $window.flightHistory.saveSearch();
                                $scope.processShopBot(data, vAdults, vChilds, vInfants);
                            },
                            error: function (result, status, error) {
                                console.log(status);
                                console.log(error);
                            }
                        });
                        // getBundleWidget("mrfly-widget-loader.js").done(function(){

                        // });
                        console.log("SHOPBOT CALL DONE");
                        return false;
                    }
                });
            }
        };

        $scope.$on('setSearchForm',
            function () {
                setTimeout(function () {
                    setSearchForm();
                    resumeSearch();
                    $window.flightHistory.waitForSearch();
                }, 2000);
            }
        );

        bkgData.getDpMarginFlight = function (originalMargin) {
            if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {
                //never ever use totalPriceForcedPrice in B2B!
                var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
                var mandatoryOptionsFees = 0;
                if (proposal.mandatoryFees) {
                    mandatoryOptionsFees = proposal.mandatoryFees.reduce(function (accumulator, mandatoryFee) {
                        return accumulator + mandatoryFee.totalPrice;
                    }, 0);
                }
                if (siteTypeIsB2b) {
                    // mandatory Options
                    var insurance = 0;
                    if ($scope.bkgData.insurancesSelected.isRepatriament != null && $scope.bkgData.insurancesSelected.isRepatriament.amount != null) {
                        insurance = parseFloat($scope.bkgData.insurancesSelected.isRepatriament.amount);
                    }
                    var totalAmount = ($scope.bkgData.prixTotalWithoutMargin.value - insurance);
                    var dpMarginFlight = getAmountFromMargin(totalAmount, $scope.bkgData.agencyMarginHotel.margin);
                    console.log("FlightBookingBody.B2B.getDpMarginFlight", dpMarginFlight);
                    return dpMarginFlight;
                }
                else {
                    var dpMarginFlight = originalMargin;
                    console.log("FlightBookingBody.Other.getDpMarginFlight", dpMarginFlight);
                    return dpMarginFlight;
                }
            } else {
                var dpMarginFlight = originalMargin;
                console.log("FlightBookingBody.Other.getDpMarginFlight", dpMarginFlight);
                return dpMarginFlight;
            }
        };

        bkgData.getDpMarginHotel = function () {
            if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {
                if (siteTypeIsB2b) {
                    // mandatory Options
                    var totalAmount = $scope.bkgData.accommodations[0].totalPriceRacknet;
                    var dpMarginHotel = getAmountFromMargin(totalAmount, $scope.bkgData.agencyMarginHotel.margin);
                    console.log("FlightBookingBody.B2B.getDpMarginHotel", dpMarginHotel);
                    return dpMarginHotel;
                }
                else {
                    var dpMarginHotel = 0;
                    console.log("FlightBookingBody.Other.getDpMarginHotel", dpMarginHotel);
                    return dpMarginHotel;
                }
            } else {
                var dpMarginHotel = 0;
                console.log("FlightBookingBody.Other.getDpMarginHotel", dpMarginHotel);
                return dpMarginHotel;
            }
        };


        bkgData.totalBasketPrice = function () {
            if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {
                //never ever use totalPriceForcedPrice in B2B!
                var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
                var mandatoryOptionsFees = 0;
                if (proposal.mandatoryFees) {
                    mandatoryOptionsFees = proposal.mandatoryFees.reduce(function (accumulator, mandatoryFee) {
                        return accumulator + mandatoryFee.totalPrice;
                    }, 0);
                }
                if (siteTypeIsB2b) {
                    // mandatory Options
                    var insurance = 0;
                    if ($scope.bkgData.insurancesSelected.isRepatriament != null && $scope.bkgData.insurancesSelected.isRepatriament.amount != null) {
                        insurance = parseFloat($scope.bkgData.insurancesSelected.isRepatriament.amount);
                    }
                    var totalAmount = ($scope.bkgData.prixTotalWithoutMargin.value - insurance + $scope.bkgData.accommodations[0].totalPriceRacknet);
                    $scope.bkgData.agencyMarginHotel.value = getAmountFromMargin(totalAmount, $scope.bkgData.agencyMarginHotel.margin);
                    var basketPrice = totalAmount + $scope.bkgData.agencyMarginHotel.value + insurance + mandatoryOptionsFees;
                    console.log("FlightBookingBody.B2B.basketPrice", basketPrice);
                    return basketPrice;
                }
                else {
                    var basketPrice = $scope.bkgData.prixTotal.value + $scope.bkgData.accommodations[0].totalPriceForcedPrice + mandatoryOptionsFees;
                    console.log("FlightBookingBody.Other.basketPrice", basketPrice);
                    return basketPrice;
                }
            } else {
                var basketPrice = $scope.bkgData.prixTotal.value;
                console.log("FlightBookingBody.Other.basketPrice", basketPrice);
                return basketPrice;
            }
        };

        $scope.bkgData = bkgData;

        $(document).on('gotuserinfo', function (event, data, forceUpdate) {

            //autocomplete
            if (data && $scope.bkgData.bookingRequest) {
                $scope.bkgData.bookingRequest.buyer = data.userInfos.buyer;
                $scope.bkgData.bookingRequest.contact = data.userInfos.contact;
                if (forceUpdate) {
                    $scope.$apply();
                }
            }
        });


        $scope.$watch('bkgData', function () {
            $(document).trigger('gotuserinfo', $window.userInfoResult, false);
        });


        $scope.$on('countryLanguageChanged', function (event, args) {
            $scope.selectDocumentsLanguage = args;
        });

        $scope.sendFacebookData = function () {
            //Cdiscount
            if (window.site.partnerId == 'cdiscountvol') {
                var departingDepartureDate = moment($scope.bkgData.purchaseResponse.itineraries.first().departureDateTime).format("YYYY-MM-DD");
                var departingArrivalDate = moment($scope.bkgData.purchaseResponse.itineraries.first().arrivalDateTime).format("YYYY-MM-DD");
                var returningDepartureDate = $scope.requestSummary.oneWay ? '' : moment($scope.bkgData.purchaseResponse.itineraries.last().departureDateTime).format("YYYY-MM-DD");
                var returningArrivalDate = $scope.requestSummary.oneWay ? '' : moment($scope.bkgData.purchaseResponse.itineraries.last().arrivalDateTime).format("YYYY-MM-DD");

                var destinationAirport = $scope.bkgData.purchaseResponse.itineraries.first().destination.airportIata;
                if ($scope.requestSummary.multidestination) {
                    destinationAirport = $scope.bkgData.purchaseResponse.itineraries.last().destination.airportIata;
                }

                var cabinClassName = function (item) {
                    switch (item) {
                        case "Y":
                            return "economy";
                        case "S":
                            return "premium";
                        case "C":
                            return "business";
                        case "F":
                            return "first";
                        default:
                            return "economy";
                    }
                };

                function waitForfbq() {
                    if (typeof fbq === "undefined") {
                        requestAnimationFrame(waitForfbq);
                        return;
                    }
                    fbq('track', 'ViewContent', {
                        type_service: 'VOYAGES',
                        content_category: 'VOL',
                        content_type: 'flight',
                        content_ids: $scope.requestSummary.origin.cityIATA + '-' + $scope.requestSummary.destination.cityIATA,
                        value: $scope.bkgData.prixTotal.value,
                        currency: 'EUR',
                        origin_airport: $scope.bkgData.purchaseResponse.itineraries.first().origin.airportIata,
                        destination_airport: destinationAirport,
                        departing_departure_date: departingDepartureDate,
                        returning_departure_date: returningDepartureDate,
                        departing_arrival_date: departingArrivalDate,
                        returning_arrival_date: returningArrivalDate,
                        num_adults: $scope.requestSummary.adults > 0 ? $scope.requestSummary.adults : 0,
                        num_children: $scope.requestSummary.childs > 0 ? $scope.requestSummary.childs : 0,
                        num_infants: $scope.requestSummary.infants > 0 ? $scope.requestSummary.infants : 0,
                        travel_class: cabinClassName($scope.requestSummary.cabinClass),
                        compagnie: $scope.bkgData.purchaseResponse.itineraries[0].marketingCarrier.name,
                        preferred_num_stops: $scope.bkgData.purchaseResponse.itineraries[0].stops
                    });
                }

                requestAnimationFrame(waitForfbq);
            }
        }

        $scope.prepareBasketPaxInfo = function (passengers) {
            //Basket
            if ($scope.hasFlightHotelFormSummary == false && $scope.isUpsell) {

            }

            if ($scope.hasFlightHotelFormSummary == true) {
                //read json
                var hotelSummaryJson = getCookie("FlightHotelFormSummary");
                var hotelSummary = JSON.parse(hotelSummaryJson);
                var ageArray = [];

                hotelSummary.rooms.forEach((room, index) => {
                    for (var i = 0; i < room.adultNumber; i++) {
                        ageArray.push({ room: index, type: "ADT", age: -1 })
                    }
                });

                hotelSummary.rooms.forEach((room, index) => {
                    room.childrenAge.forEach(childAge => {
                        if (childAge < 2) {
                            ageArray.push({ room: index, type: "INF", age: childAge })
                        } else {
                            ageArray.push({ room: index, type: "CHD", age: childAge })
                        }
                    });
                });

                passengers.forEach((passenger, index) => {
                    if (ageArray[index].age >= 0 && ageArray[index].age < 2) {
                        passenger.paxTypeHotel = "INF";
                        passenger.paxRoom = ageArray[index].room;
                        passenger.specificAge = ageArray[index].age;
                        passenger.forceSpecificAge = true;
                    }

                    if (ageArray[index].age >= 2 && ageArray[index].age < 18) {
                        passenger.paxTypeHotel = "CHD";
                        passenger.paxRoom = ageArray[index].room;
                        passenger.specificAge = ageArray[index].age;
                        passenger.forceSpecificAge = true;
                    }
                    if (ageArray[index].age == -1) {
                        passenger.paxTypeHotel = "ADT";
                        passenger.paxRoom = ageArray[index].room;
                        passenger.specificAge = ageArray[index].age;
                        passenger.forceSpecificAge = true;
                    }
                })

                console.log(passengers);
            }
        }

        $scope.prepareAccommodation = function (accommodation) {
            //nights number
            var nights = moment(accommodation.to).diff(moment(accommodation.from), "days");
            accommodation.nightNumber = nights;

            var adultNumber = 0;
            var childNumber = 0;
            var infantNumber = 0;

            //roomplan
            for (i = 0; i < accommodation.rooms.length; i++) {
                var roomPlan = accommodation.rooms[i].roomPlan;

                accommodation.rooms[i].adultNumber = parseInt(roomPlan.substr(0, 1));
                accommodation.rooms[i].childNumber = parseInt(roomPlan.substr(1, 1));
                accommodation.rooms[i].infantNumber = parseInt(roomPlan.substr(2, 1));

                adultNumber += parseInt(roomPlan.substr(0, 1));
                childNumber += parseInt(roomPlan.substr(1, 1));
                infantNumber += parseInt(roomPlan.substr(2, 1));
            }

            var totalHotelPax = adultNumber + childNumber + infantNumber;
            var totalFlightPax = $scope.paxAmount[0] + $scope.paxAmount[1] + $scope.paxAmount[2];

            accommodation.hasValidPaxTypes = totalHotelPax == totalFlightPax;

            $scope.invalidPaxTypes = !accommodation.hasValidPaxTypes;

        };

        $scope.AddPaxToRoom = function (room, ptctype) {

            $scope.bkgData.bookingRequest.passengers.forEach(pax => {
                if ((pax.paxType.toLowerCase() == ptctype.toLowerCase()) && pax.alreadyInRoom == false) {
                    pax.alreadyInRoom = true;
                    room.passengers.push(pax);
                    return true;
                }
            });

            return false;
        }

        $scope.getRoomByAgeStr = function (rooms, ageStr) {

            if (ageStr == undefined) {
                ageStr = null;
            }

            for (var i = 0; i < rooms.length; i++) {
                var r = rooms[i];
                console.log("r.roomPlanAges");
                console.log(r.roomPlanAges);
                if ((r.roomPlanAges == ageStr) && (r.alreadyOrdered != true)) {
                    r.alreadyOrdered = true;
                    return r;
                }

            }

            //If not found take the first availble room
            for (var i = 0; i < rooms.length; i++) {
                var r = rooms[i];
                if (r.alreadyOrdered != true) {
                    r.alreadyOrdered = true;
                    return r;
                }
            }

            return null;
        }

        $scope.LoadVueJsWidgets = function (purchaseResponse) {

            var getPurchaseRQ = {
                purchaseId: purchaseResponse.purchaseId,
                requestId: $scope.bkgData.request.requestId,
                ceUser: $window.site.loginInfo?.Matricule
            };

            $scope.apiClient.ACCOMMODATION.getPurchase(getPurchaseRQ).success(function (data) {

                console.log("LoadVueJsWidgets data", data);

                if (data.accommodations.any()) {

                    //HOTEL TIMER
                    var timerkey = "hoteltimerstart_" + purchaseResponse.purchaseId;
                    if (!$window.hoteltimer && window.sessionStorage.getItem(timerkey)) {
                        var timerdate = window.sessionStorage.getItem(timerkey);

                        var diff = moment(new Date()).diff(moment(timerdate));
                        //var diffMinutes = moment.duration(diff).asMinutes();
                        var limit = 15 * 60000; //convert into ms

                        if (diff < limit) {
                            var timeoutId = setTimeout(function () { console.log("trigger alert"); }, limit - diff);
                            window.sessionStorage.setItem('hoteltimerid', timeoutId);//stored to be used when calling clearTimeout()
                        }
                    }

                    var accommodationKey = 'accommodation_' + data.accommodations[0].accommodationId;
                    var acc = $window.sessionStorage.getItem(accommodationKey);
                    if (acc) {
                        data.accommodations[0] = JSON.parse(acc);
                    }

                    //Set rooms passengers for basket
                    if ($scope.hasFlightHotelFormSummary) {
                        var roomAgeStr = [];

                        var hotelSummaryJson = getCookie("FlightHotelFormSummary");
                        var hotelSummary = JSON.parse(hotelSummaryJson);

                        hotelSummary.rooms.forEach((room, index) => {
                            console.log("hotelSummary.rooms " + room.childrenAge.length + " : " + index);
                            room.childrenAge.forEach(childAge => {
                                if (childAge >= 2) {
                                    if (roomAgeStr[index] == undefined) {
                                        roomAgeStr[index] = childAge;
                                    } else {
                                        roomAgeStr[index] += "-" + childAge;
                                    }
                                } else {
                                    if (roomAgeStr[index] == undefined) {
                                        roomAgeStr[index] = undefined;
                                    }
                                }
                            });

                            if (room.childrenAge.length == 0) {

                                roomAgeStr[index] = undefined;
                            }
                        });

                        console.log("roomAgeStrroomAgeStr");
                        console.log(roomAgeStr);

                        var rooms = data.accommodations[0].rooms;

                        var orderedRooms = [];

                        for (var i = 0; i < roomAgeStr.length; i++) {
                            var ageStr = roomAgeStr[i];
                            var room = $scope.getRoomByAgeStr(rooms, ageStr);
                            orderedRooms.push(room);
                        }
                        console.log("orderedRooms orderedRooms");
                        console.log(orderedRooms);

                        data.accommodations[0].rooms = orderedRooms;

                        data.accommodations[0].rooms.forEach(room => {
                            room.passengers = [];
                        });

                        $scope.bkgData.bookingRequest.passengers.forEach(pax => {
                            data.accommodations[0].rooms[pax.paxRoom].passengers.push(pax);
                        });

                        console.log("data.accommodations[0] --");
                        console.log(data.accommodations);
                    }
                    //Set rooms passengers for upsell
                    if (!$scope.hasFlightHotelFormSummary) {
                        data.accommodations[0].rooms.forEach(room => {
                            room.passengers = [];
                        });

                        $scope.bkgData.bookingRequest.passengers.forEach(pax => {
                            pax.alreadyInRoom = false;
                        });

                        data.accommodations[0].rooms.forEach(room => {
                            var adultNumber = parseInt(room.roomPlan.substr(0, 1));
                            var childNumber = parseInt(room.roomPlan.substr(1, 1));
                            var infantNumber = parseInt(room.roomPlan.substr(2, 1));
                            console.log("ata.accommodations[0].rooms adt:" + adultNumber + " chd:" + childNumber + " inf:" + infantNumber);
                            for (let i = 0; i < adultNumber; i++) {
                                $scope.AddPaxToRoom(room, "0");
                            }

                            for (let i = 0; i < childNumber; i++) {
                                $scope.AddPaxToRoom(room, "1");
                            }

                            for (let i = 0; i < infantNumber; i++) {
                                $scope.AddPaxToRoom(room, "2");
                            }
                        });
                    }


                    $scope.accommodation = data.accommodations[0];
                    $scope.accommodations = data.accommodations;

                    $window.accommodation = data.accommodations[0];
                    $scope.bkgData.accommodations = data.accommodations;

                    var marginHotel = 0;
                    if (data.agencyMarginHotel) {
                        if (siteTypeIsB2b) {
                            var totalAmount = data.proposals[0].totalAmount + data.accommodations[0].totalPriceRacknet;
                            if ($scope.bkgData.purchaseResponse.proposalsRequested.totalAgencyHotelMargin) {
                                marginHotel = getAmountFromMargin(totalAmount, $scope.bkgData.purchaseResponse.proposalsRequested.totalAgencyHotelMargin.value)
                            }
                            else {
                                marginHotel = getAmountFromMargin(totalAmount, data.agencyMarginHotel.margin);
                            }
                        }
                        else {
                            if ($scope.bkgData.purchaseResponse.proposalsRequested.totalAgencyHotelMargin) {
                                marginHotel = getAmountFromMargin(totalAmount, $scope.bkgData.purchaseResponse.proposalsRequested.totalAgencyHotelMargin);
                            }
                            else {
                                marginHotel = getAmountFromMargin(totalAmount, data.agencyMarginHotel.margin);
                            }
                        }

                        //required to be saved in sessionstorage
                        bkgData.agencyMarginHotel = {
                            margin: data.agencyMarginHotel.margin,
                            value: marginHotel
                        };


                        $scope.bkgData.agencyMarginHotel = bkgData.agencyMarginHotel;
                    }

                    $scope.prepareAccommodation(data.accommodations[0]);

                    getBundleWidget("mrfly-widget-accommodation-info.js").done(function () {

                        //nights number
                        var nights = moment(data.accommodations[0].to).diff(moment(data.accommodations[0].from), "days");
                        data.accommodations[0].nightNumber = nights;

                        mrflyWidgets['mrfly-widget-accommodation-info'].locale = $window.lng.substring(0, 2);
                        mrflyWidgets['mrfly-widget-accommodation-info'].accommodation = data.accommodations[0];
                        mrflyWidgets['mrfly-widget-accommodation-info'].siteType = window.site.type;
                        mrflyWidgets['mrfly-widget-accommodation-info'].agencyMarginHotel = marginHotel;
                    }).fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });


                    if ($scope.invalidPaxTypes === false) {
                        getBundleWidget("mrfly-widget-basket-total-price.js").done(function () {
                            mrflyWidgets['mrfly-widget-basket-total-price'].price = $scope.bkgData.totalBasketPrice();
                            var extraDetails = [];
                            var bags = basketHelper.getAdditionalBagsPriceDetails($scope.siteType, $scope.bkgData.bookingRequest.passengers);
                            var insurances = basketHelper.getInsurancePriceDetails($scope.siteType, $scope.bkgData.bookingRequest.insurance, $scope.bkgData.purchaseResponse.proposalsRequested.insurances);

                            Array.prototype.push.apply(extraDetails, bags);
                            Array.prototype.push.apply(extraDetails, insurances);

                            //Seatmap
                            var seatmapObj = JSON.parse(window.sessionStorage.getItem('selectedSeats_' + $scope.bkgData.purchaseResponse.purchaseId));
                            console.log(">>----");
                            console.log(seatmapObj);
                            if (seatmapObj != null) {
                                var seatmap = basketHelper.getSeatMapPriceDetails($scope.siteType, seatmapObj);
                                Array.prototype.push.apply(extraDetails, seatmap);
                            }

                            var basketDetails = basketHelper.getPriceDetails(
                                $scope.siteType,
                                $scope.bkgData.purchaseResponse.proposalsRequested,
                                data.accommodations,
                                $scope.bkgData.agencyMarginHotel,
                                extraDetails
                            );

                            console.log("basketDetails");
                            console.log(basketDetails);

                            mrflyWidgets['mrfly-widget-basket-total-price'].basketDetails = basketDetails;
                        });

                    }

                    if (trackingService.isActivated()) {
                        if (trackingService.pageViewEvent) {
                            trackingService.pageViewEvent("summary_page", "airbooking");
                        }
                    }
                }
            });


        };

        /*
        $scope.BindPaxForm = function (accommodation, accommodationKey, passengers) {
            mrflyWidgets['mrfly-widget-accommodation-pax-form'].resources = i18next.store.data.dev.translation;
            mrflyWidgets['mrfly-widget-accommodation-pax-form'].accommodation = accommodation;
            //Set pax age

            hotelHelper.getPassangerHotelType(passengers, $scope.bkgData.purchaseResponse.itineraries[1].date);

            console.log("Hotel passengers");
            console.log(passengers);
            mrflyWidgets['mrfly-widget-accommodation-pax-form'].paxes = passengers;
            mrflyWidgets['mrfly-widget-accommodation-pax-form'].$on('updated', function (isValid, filledAccommodation) {
                if (isValid) {
                    $("#Step1Button").show();
                    $scope.accommodation = filledAccommodation;
                    $window.accommodation = filledAccommodation;
                    $window.sessionStorage.setItem(accommodationKey, JSON.stringify(filledAccommodation));
                }
                else {
                    $("#Step1Button").hide();
                }
            });
        };

        */

        //Check Hide insurance and hide flexy values
        if (user && user.loginInfo && siteTypeIsB2b) {
            if (user.loginInfo.HideInsurance == true) {
                $scope.hideInsurance = true;
            }

            if (user.loginInfo.HideFlexy == true) {
                $scope.hideFlexy = true;
            }
        }

        if (bkgData) {

            //Pax Init
            var paxType = ["Adulte", "Enfant", "Bébé"];
            var paxTypeAbv = ["ADT", "CNN", "INF"];
            var paxAmount = [bkgData.request.adults, bkgData.request.childs, bkgData.request.infants];
            var keyGenerated = "";
            var init = true;

            $scope.paxType = paxType;
            $scope.paxAmount = paxAmount;
            $scope.newProcess = true;
            bkgData.pricePredictions = null;

            //Key Generated: requestId_purchaseRequestIndex_purchaseRequestGroup_purchaseRequestProposal
            keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;

            // BookingRequest  Object to send to API
            bkgData.bookingRequest = tseUtil.Session.get("bookingRequest_" + keyGenerated);
            bkgData.paymentMethods = tseUtil.Session.get("paymentMethods" + keyGenerated);


            if (bkgData.bookingRequest != null && bkgData.bookingRequest.requestId == bkgData.request.requestId) {
                init = false;
            }

            //Initialize BookingRequest Object
            if (init) {

                //Insurance:  { company : "00001", reference: "00001" }
                //Passengers: { title, firstName, lastName, birthDate, fidelityCardType, fidelityCardNumber, foodType, passportNumber, passportExpireDate, passportCountry }                       
                bkgData.bookingRequest = {
                    proposalIndex: "",
                    bagChanged: false,
                    requestId: bkgData.request.requestId,
                    flexyFare: false,
                    insurance: null,
                    passengers: [],
                    additionalData: [],
                    buyer: {},
                    contact: { mail: '', phone: '' },
                    bestPriceFare: false
                };

                if (existWonderboxCode)
                    bkgData.bookingRequest.wonderboxCode = user.loginInfo.Code;

                //autocomplete
                //if (userInfo && userInfo.isLogged && userInfo.userInfos) {
                //    //pas d'autocomplete pour les passagers 
                //    //bkgData.bookingRequest.passengers.push(userInfo.userInfos.passenger);
                //    bkgData.bookingRequest.buyer = userInfo.userInfos.buyer;
                //    bkgData.bookingRequest.contact = userInfo.userInfos.contact;
                //}

                var idx = 0;

                for (var ptc in paxAmount) {

                    if (paxAmount.hasOwnProperty(ptc)) {
                        for (var i = 0; i < paxAmount[ptc]; i++) {
                            bkgData.bookingRequest.passengers.push({ paxType: ptc, ptc: paxTypeAbv[ptc], index: idx });
                            idx++;
                        }
                    }

                }

                $scope.prepareBasketPaxInfo(bkgData.bookingRequest.passengers);
            }

            var purchaseResponse = tseUtil.Session.get("purchaseResponse_" + keyGenerated);


            //Get values from session storage
            var _insuranceSelected = tseUtil.Session.get("insurancesSelected_" + keyGenerated);
            var _insuranceOnce = tseUtil.Session.get("insuranceOnce" + keyGenerated);
            var _purchaseResponse = tseUtil.Session.get("purchaseResponse_" + keyGenerated);
            //console.log(_purchaseResponse);
            bkgData.paypalToken = tseUtil.Session.get("paypalToken" + keyGenerated);
            bkgData.banqueCasinoSchedule = tseUtil.Session.get("banqueCasino" + keyGenerated);
            bkgData.banqueCasino = tseUtil.Session.get("banqueCasinoStatus" + keyGenerated);
            bkgData.banqueCasinoMessage = tseUtil.Session.get("banqueCasinoMessage" + keyGenerated);
            bkgData.banqueCasinoCustomerUnauthorized = tseUtil.Session.get("banqueCasinoCustomerUnauthorized" + keyGenerated);
            bkgData.showBanqueCasino = tseUtil.Session.get("banqueCasinoShowBanqueCasino" + keyGenerated);
            bkgData.paymentMethods = tseUtil.Session.get("paymentMethods" + keyGenerated);

            if ($window.sessionStorage.getItem("userInfoResult")) {

                userInfo = JSON.parse($window.sessionStorage.getItem("userInfoResult"));

                bkgData.bookingRequest.buyer = userInfo.userInfos.buyer;
                bkgData.bookingRequest.contact = userInfo.userInfos.contact;
                $scope.$apply();
            }

            if (_insuranceSelected && !init) {
                bkgData.insurancesSelected = _insuranceSelected;
                bkgData.insuranceOnce = _insuranceOnce;
            } else {
                bkgData.insurancesSelected = {};
                bkgData.insuranceOnce = 0;
            }

            //Tracking
            try {
                //GTM
                if (trackingService.isActivated()) {
                    trackingService.initDataLayer('checkout');


                }
            }
            catch (ex) {
                console.log(ex.message);
                $window.trackingError = ex.message;
            }

            var codeParam = $window.getUrlVars()['code'];
            var hotelAdded = typeof codeParam !== 'undefined' && codeParam === '1';
            var upsellParam = $window.getUrlVars()['upsell'];
            var isUpsell = typeof upsellParam !== 'undefined' && upsellParam === 'true';

            window.sessionStorage.setItem('forceUpsell', hotelAdded && isUpsell);

            //Purchase Response

            if (_purchaseResponse && !init) {
                bkgData.purchaseResponse = _purchaseResponse;
                purchaseResponse.value = _purchaseResponse;
                console.log("_purchaseResponse");
                console.log(bkgData.purchaseResponse.proposals[0].passengers);
                $scope.prepareBasketPaxInfo(bkgData.purchaseResponse.proposals[0].passengers);
                bkgData.severalProposals = bkgData.purchaseResponse.proposals.length > 1;
                $window.FLoading({ vAction: 'hide' });
                $(".c-footer").removeClass("d-none");

                if (bkgData.purchaseResponse != null && bkgData.purchaseResponse.itineraries != null && bkgData.purchaseResponse.itineraries.any()) {
                    $scope.lastDate = bkgData.purchaseResponse.itineraries[bkgData.purchaseResponse.itineraries.length - 1].arrivalDateTime;
                }

                //Tracking
                try {

                    $window.$scope.bkgData.request.totalAmount = purchaseResponse.value.proposals[0].totalAmount;
                    //GTM
                    if (trackingService.isActivated()) {
                        trackingService.addProduct(gtmProduct.parseRequest($scope.bkgData.request).applyPrice(purchaseResponse.value.proposals[0].totalAmount));
                        trackingService.parseScope($scope);
                        trackingService.pushDataLayer('step', 1);
                        if (trackingService.pageViewEvent) {
                            trackingService.pageViewEvent("summary_page", "airbooking");
                        }
                        if (trackingService.interactionEvent) {
                            trackingService.interactionEvent("funnel_step1", "summary page", "1");
                        }
                    }
                    //omniture
                    if (omnitureService.isActivated()) {
                        console.info("=========================  send cdtm Tunnel 1 -- 1/3 =================");
                        omnitureService.sendCdtm(omnitureService.PURCHASE_1_VOL, $scope.bkgData);
                        omnitureService.sendEvent(omnitureService.PURCHASE_1_VOL, omnitureService.FLIGHT_TUNNEL, { request: $scope.bkgData.request, totalAmount: purchaseResponse.value.proposals[0].totalAmount });
                    }

                    $scope.sendFacebookData();

                }
                catch (ex) {
                    console.log(ex.message);
                    $window.trackingError = ex.message;
                }
                ///If upSellProducts  is enable check for accommodation

                if ($window.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true') {
                    var getPurchaseRQ = {
                        purchaseId: purchaseResponse.purchaseId,
                        requestId: $scope.bkgData.request.requestId,
                        ceUser: $window.site.loginInfo?.Matricule
                    };
                    $scope.LoadVueJsWidgets(_purchaseResponse);


                }
                $scope.$emit("setSearchForm");


            } else if (_purchaseResponse && init) {
                bkgData.purchaseResponse = _purchaseResponse;
                purchaseResponse.value = _purchaseResponse;
                $scope.prepareBasketPaxInfo(bkgData.purchaseResponse.proposals[0].passengers);
                bkgData.severalProposals = bkgData.purchaseResponse.proposals.length > 1;

                console.log('_purchaseResponse && init');
                console.log($window.$scope.bkgData);

                $window.FLoading({ vAction: 'hide' });

                if (bkgData.purchaseResponse != null && bkgData.purchaseResponse.itineraries != null && bkgData.purchaseResponse.itineraries.any()) {
                    $scope.lastDate = bkgData.purchaseResponse.itineraries[bkgData.purchaseResponse.itineraries.length - 1].arrivalDateTime;
                }

                //If upSellProducts  is enable check for accommodation
                if ($window.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true') {
                    $scope.LoadVueJsWidgets(_purchaseResponse);
                }
            }
            else {
                purchaseResponse = { value: "" };

                //A changer par la config servair pour le moment on utilise 1==1
                var apiToken = getCookie("authToken_" + window.site.partnerId);
                var loginId = getCookie("lid_" + $window.site.partnerId);
                var purchaseNewServairRequest = {};
                purchaseNewServairRequest.purchaseRequest = bkgData.purchaseRequest;
                purchaseNewServairRequest.apiToken = apiToken;
                purchaseNewServairRequest.loginId = loginId;
                purchaseNewServairRequest.fare = [];
                purchaseNewServairRequest.selectedProduct = purchaseNewServairRequest.purchaseRequest.index.toString() + purchaseNewServairRequest.purchaseRequest.group.toString();
                window.sessionStorage.setItem("selectedProduct", purchaseNewServairRequest.purchaseRequest.index.toString() + purchaseNewServairRequest.purchaseRequest.group.toString());
                if (window.site.partnerId === "servair") {
                    $window.Typescript.Servair.Services.ServairService.getPurchaseRequest(purchaseNewServairRequest).then(function (result) {

                        var info = result.data;
                        var data = JSON.parse(info)
                        if (data && data.message == null) {
                            console.log("DATA");
                            console.log(data);

                            airHelper.preparePurchaseData(data);
                            bkgData.purchaseResponse = data;
                            purchaseResponse.value = data;
                            $scope.prepareBasketPaxInfo(bkgData.purchaseResponse.passengers);
                            bkgData.severalProposals = bkgData.purchaseResponse.proposals.length > 1;
                            bkgData.bookingRequest.proposalIndex = bkgData.purchaseResponse.proposals[0].index;
                            $scope.lastDate = bkgData.purchaseResponse.itineraries[bkgData.purchaseResponse.itineraries.length - 1].arrivalDateTime;

                            if (bkgData.severalProposals && bkgData.purchaseResponse.brandedFaresServices.length > 1)
                                airHelper.prepareBrandedFaresServicesData(bkgData.purchaseResponse.brandedFaresServices);

                            if (init)
                                airHelper.preparePassengersData(bkgData.bookingRequest.passengers, bkgData.purchaseResponse);

                            //Predictions
                            var predictions = $scope.preparePredictionsObject();
                            var counterPax = 0;
                            //clear array

                            bkgData.bookingRequest.passengers.length = 0;

                            //adultes en premier
                            bkgData.purchaseResponse.ayantDroitPricing.filter(function (x) { return x.paxType == 0 }).forEach(function (passenger) {

                                bkgData.bookingRequest.passengers.push({
                                    "paxType": passenger.paxType,
                                    "ptc": "ADT",
                                    "index": counterPax,
                                    // "fidelityCardType": "00055",
                                    "baggageByItinerary": {},
                                    "firstName": passenger.fisrtName,
                                    "lastName": passenger.lastName,
                                    "title": passenger.gender ? "1" : "2", //Gender = true -> Masculin(1) / Gender = False -> Feminin (2)
                                    "birthDate": moment(passenger.birthdate).format("DD/MM/YYYY"),
                                    "type": passenger.type,
                                    "externalUserId": passenger.externalUserId,
                                    "id": passenger.id,
                                });
                                counterPax++;
                            });
                            //puis enfants
                            bkgData.purchaseResponse.ayantDroitPricing.filter(function (x) { return x.paxType == 1 }).forEach(function (passenger) {

                                bkgData.bookingRequest.passengers.push({
                                    "paxType": passenger.paxType,
                                    "ptc": "CNN",
                                    "index": counterPax,
                                    // "fidelityCardType": "00055",
                                    "baggageByItinerary": {},
                                    "firstName": passenger.fisrtName,
                                    "lastName": passenger.lastName,
                                    "title": passenger.gender ? "1" : "2", //Gender = true -> Masculin(1) / Gender = False -> Feminin (2)
                                    "birthDate": moment(passenger.birthdate).format("DD/MM/YYYY"),
                                    "type": passenger.type,
                                    "id": passenger.id,
                                    "externalUserId": passenger.externalUserId
                                });
                                counterPax++;
                            });

                            //puis bébés
                            bkgData.purchaseResponse.ayantDroitPricing.filter(function (x) { return x.paxType == 2 }).forEach(function (passenger) {

                                bkgData.bookingRequest.passengers.push({
                                    "paxType": passenger.paxType,
                                    "ptc": "INF",
                                    "index": counterPax,
                                    // "fidelityCardType": "00055",
                                    "baggageByItinerary": {},
                                    "firstName": passenger.fisrtName,
                                    "lastName": passenger.lastName,
                                    "title": passenger.gender ? "1" : "2", //Gender = true -> Masculin(1) / Gender = False -> Feminin (2)
                                    "birthDate": moment(passenger.birthdate).format("DD/MM/YYYY"),
                                    "type": passenger.type,
                                    "externalUserId": passenger.externalUserId,
                                    "id": passenger.id,
                                });
                                counterPax++;
                            });


                            counterPax = 0;

                            //Tracking
                            try {
                                $window.$scope.bkgData.request.totalAmount = purchaseResponse.value.proposals[0].totalAmount;
                                //GTM
                                if (trackingService.isActivated()) {
                                    trackingService.addProduct(gtmProduct.parseRequest($scope.bkgData.request).applyPrice(purchaseResponse.value.proposals[0].totalAmount));
                                    trackingService.parseScope($scope);
                                    trackingService.pushDataLayer('step', 1);
                                    if (trackingService.pageViewEvent) {
                                        trackingService.pageViewEvent("summary_page", "airbooking");
                                    }
                                    if (trackingService.interactionEvent) {
                                        trackingService.interactionEvent("funnel_step1", "summary page", "1");
                                    }
                                }

                                //omniture
                                if (omnitureService.isActivated()) {
                                    console.info("=========================  send cdtm Tunnel 1 -- 2/3 =================");
                                    omnitureService.sendCdtm(omnitureService.PURCHASE_1_VOL, $scope.bkgData);
                                    omnitureService.sendEvent(omnitureService.PURCHASE_1_VOL, omnitureService.FLIGHT_TUNNEL, { request: $scope.bkgData.request, totalAmount: purchaseResponse.value.proposals[0].totalAmount });
                                }

                                $scope.sendFacebookData();
                            }
                            catch (ex) {
                                console.log(ex.message);
                                $window.trackingError = ex.message;
                            }

                            /*train process result*/
                            angular.forEach(bkgData.purchaseResponse.itineraries, function (iti, indexI) {
                                if (iti.segments.length == 1 && iti.segments[0].equipment.reference == "TRN") $scope.bkgData.purchaseResponse.itineraries[indexI].isTrainOnly = true;
                                else {
                                    var isTrainOnly = false;
                                    for (var i = 0; i < iti.segments.length; i++) {
                                        if (iti.segments[i].equipment.reference == "TRN") isTrainOnly = true;
                                        else if (iti.segments[i].equipment.reference != "TRN") {
                                            isTrainOnly = false;
                                            break;
                                        }
                                    }
                                    $scope.bkgData.purchaseResponse.itineraries[indexI].isTrainOnly = isTrainOnly;
                                }
                            });

                            if ($scope.bkgData.purchaseResponse.itineraries.every(function (itinerary) { return itinerary.isTrainOnly === true; })) {
                                $scope.bkgData.purchaseResponse.productIsTrain = true;
                            }

                            if ($scope.bkgData.purchaseResponse.productIsTrain === true) {
                                $scope.hideInsurance = true;
                            }

                            $window.FLoading({ vAction: 'hide' });
                            $(".c-footer").removeClass("d-none");

                        }
                        else {
                            $window.location.href = $window.site.errorsUrls.GeneralError;

                        }

                    }).finally(function () {
                        $scope.$emit("setSearchForm");
                        $scope.$apply();
                        //$window.location.reload(); 
                    });
                }
                else {
                    var cnasAyantDroit = {};

                    var newPurchaseCallBack = function () {
                        console.info("called only once, new purchase");
                        //New Purchase Transaction
                        $scope.apiClient.PURCHASE.new(bkgData.purchaseRequest)
                            .success(function (data, code) {
                                if (data && data.message == null) {

                                    var labels = new Object();
                                    labels['status'] = "OK";
                                    window.Typescript.Services.MetricService.SendEvent(window.metricUrlApi, "purchaseNew", labels);
                                    //TODO FFV2: see if still needed
                                    //if (data.proposals[0].flexies && data.proposals[0].flexies.length > 0) {
                                    //    $scope.enableFreeFlex = data.proposals[0].flexies[0].propose === 2;
                                    //}
                                    airHelper.preparePurchaseData(data);
                                    bkgData.purchaseResponse = data;
                                    purchaseResponse.value = data;
                                    $scope.prepareBasketPaxInfo(bkgData.purchaseResponse.passengers);
                                    if (window.site.partnerId === "cnas" || window.site.partnerId === "cnasdomtom" || window.site.partnerId === "adosspp") {
                                        bkgData.bookingRequest.passengers.length = 0;
                                        var counterPax = 0;
                                        //adultes en premier
                                        cnasAyantDroit.data.filter(function (x) { return x.paxType == 0 }).forEach(function (passenger) {

                                            bkgData.bookingRequest.passengers.push({
                                                "paxType": passenger.paxType,
                                                "ptc": "ADT",
                                                "index": counterPax,
                                                // "fidelityCardType": "00055",
                                                "baggageByItinerary": {},
                                                "firstName": passenger.fisrtName,
                                                "lastName": passenger.lastName,
                                                "title": passenger.gender ? "1" : "2", //Gender = true -> Masculin(1) / Gender = False -> Feminin (2)
                                                "birthDate": moment(passenger.birthdate).format("DD/MM/YYYY"),
                                                "type": passenger.type,
                                                "externalUserId": passenger.externalUserId,
                                                "id": passenger.id,
                                                "matricule": passenger.matricule,
                                                "partnerId": passenger.partnerId
                                            });
                                            counterPax++;
                                        });
                                        //puis enfants
                                        cnasAyantDroit.data.filter(function (x) { return x.paxType == 1 }).forEach(function (passenger) {

                                            bkgData.bookingRequest.passengers.push({
                                                "paxType": passenger.paxType,
                                                "ptc": "CNN",
                                                "index": counterPax,
                                                // "fidelityCardType": "00055",
                                                "baggageByItinerary": {},
                                                "firstName": passenger.fisrtName,
                                                "lastName": passenger.lastName,
                                                "title": passenger.gender ? "1" : "2", //Gender = true -> Masculin(1) / Gender = False -> Feminin (2)
                                                "birthDate": moment(passenger.birthdate).format("DD/MM/YYYY"),
                                                "type": passenger.type,
                                                "id": passenger.id,
                                                "externalUserId": passenger.externalUserId,
                                                "matricule": passenger.matricule,
                                                "partnerId": passenger.partnerId
                                            });
                                            counterPax++;
                                        });

                                        //puis bébés
                                        cnasAyantDroit.data.filter(function (x) { return x.paxType == 2 }).forEach(function (passenger) {

                                            bkgData.bookingRequest.passengers.push({
                                                "paxType": passenger.paxType,
                                                "ptc": "INF",
                                                "index": counterPax,
                                                // "fidelityCardType": "00055",
                                                "baggageByItinerary": {},
                                                "firstName": passenger.fisrtName,
                                                "lastName": passenger.lastName,
                                                "title": passenger.gender ? "1" : "2", //Gender = true -> Masculin(1) / Gender = False -> Feminin (2)
                                                "birthDate": moment(passenger.birthdate).format("DD/MM/YYYY"),
                                                "type": passenger.type,
                                                "externalUserId": passenger.externalUserId,
                                                "id": passenger.id,
                                                "matricule": passenger.matricule,
                                                "partnerId": passenger.partnerId
                                            });
                                            counterPax++;
                                        });


                                        counterPax = 0;

                                    }




                                    bkgData.severalProposals = bkgData.purchaseResponse.proposals.length > 1;
                                    bkgData.bookingRequest.proposalIndex = bkgData.purchaseResponse.proposals[0].index;
                                    $scope.lastDate = bkgData.purchaseResponse.itineraries[bkgData.purchaseResponse.itineraries.length - 1].arrivalDateTime;

                                    if (bkgData.severalProposals && bkgData.purchaseResponse.brandedFaresServices.length > 1)
                                        airHelper.prepareBrandedFaresServicesData(bkgData.purchaseResponse.brandedFaresServices);

                                    if (init && window.site.partnerId !== "cnas")
                                        airHelper.preparePassengersData(bkgData.bookingRequest.passengers, bkgData.purchaseResponse);
                                    var predictions = $scope.preparePredictionsObject();
                                    try {
                                        $window.$scope.bkgData.request.totalAmount = purchaseResponse.value.proposals[0].totalAmount;
                                        //GTM
                                        if (trackingService.isActivated()) {
                                            trackingService.addProduct(gtmProduct.parseRequest($scope.bkgData.request).applyPrice(purchaseResponse.value.proposals[0].totalAmount));
                                            trackingService.parseScope($scope);
                                            trackingService.pushDataLayer('step', 1);
                                            if (trackingService.pageViewEvent) {
                                                trackingService.pageViewEvent("summary_page", "airbooking");
                                            }
                                            if (trackingService.interactionEvent) {
                                                trackingService.interactionEvent("funnel_step1", "summary page", "1");
                                            }
                                        }

                                        //omniture
                                        if (omnitureService.isActivated()) {
                                            console.info("=========================  send cdtm Tunnel 1 -- 3/3 =================");
                                            omnitureService.sendCdtm(omnitureService.PURCHASE_1_VOL, $scope.bkgData);
                                            omnitureService.sendEvent(omnitureService.PURCHASE_1_VOL, omnitureService.FLIGHT_TUNNEL, { request: $scope.bkgData.request, totalAmount: purchaseResponse.value.proposals[0].totalAmount });
                                        }
                                        $scope.sendFacebookData();
                                    }
                                    catch (ex) {
                                        console.log(ex.message);
                                        $window.trackingError = ex.message;
                                    }

                                    /*train process result*/
                                    angular.forEach(bkgData.purchaseResponse.itineraries, function (iti, indexI) {
                                        if (iti.segments.length == 1 && iti.segments[0].equipment.reference == "TRN") $scope.bkgData.purchaseResponse.itineraries[indexI].isTrainOnly = true;
                                        else {
                                            var isTrainOnly = false;
                                            for (var i = 0; i < iti.segments.length; i++) {
                                                if (iti.segments[i].equipment.reference == "TRN") isTrainOnly = true;
                                                else if (iti.segments[i].equipment.reference != "TRN") {
                                                    isTrainOnly = false;
                                                    break;
                                                }
                                            }
                                            $scope.bkgData.purchaseResponse.itineraries[indexI].isTrainOnly = isTrainOnly;
                                        }
                                    });

                                    if ($scope.bkgData.purchaseResponse.itineraries.every(function (itinerary) { return itinerary.isTrainOnly === true; })) {
                                        $scope.bkgData.purchaseResponse.productIsTrain = true;
                                    }

                                    if ($scope.bkgData.purchaseResponse.productIsTrain === true) {
                                        $scope.hideInsurance = true;
                                    }

                                    $window.FLoading({ vAction: 'hide' });
                                    $(".c-footer").removeClass("d-none");

                                }
                                else {
                                    $window.location.href = $window.site.errorsUrls.GeneralError;
                                }

                            }).error(function (data, code) {
                                console.error(data);
                                var labels = new Object();
                                labels['status'] = "KO";
                                window.Typescript.Services.MetricService.SendEvent(window.metricUrlApi, "purchaseNew", labels);
                                $window.location.href = $window.site.errorsUrls.GeneralError;
                            }).finally(function () {
                                //$scope.$emit("setSearchForm");
                            });
                    }

                    if (window.site.partnerId === "cnas" || window.site.partnerId === "cnasdomtom") {
                        var cansRequest = {};
                        cansRequest.purchaseRequest = bkgData.purchaseRequest;
                        if (bkgData.request.goDate2) {
                            cansRequest.returnDate = bkgData.request.goDate2.dateString;
                        }
                        $window.Typescript.Servair.Services.ServairService.cnasAyantDroitPurchase(cansRequest).then(function (result) {
                            var info = result.data;
                            var data = JSON.parse(info)
                            cnasAyantDroit.data = data;
                            newPurchaseCallBack();
                        });
                    } else if (window.site.partnerId === "adosspp") {
                        var adossppRequest = {};
                        adossppRequest.purchaseRequest = bkgData.purchaseRequest;
                        if (bkgData.request.goDate2) {
                            adossppRequest.returnDate = bkgData.request.goDate2.dateString;
                        }
                        $window.Typescript.Adosspp.Services.AdossppService.adossppAyantDroitPurchase(adossppRequest).then(function (result) {
                            var info = result.data;
                            var data = JSON.parse(info)
                            cnasAyantDroit.data = data;
                            newPurchaseCallBack();
                        });
                    } else {
                        newPurchaseCallBack();
                    }
                }

            }

            $scope.$on("getPayPalToken", function (event, value) {
                if ($scope.enablePaypal) {
                    var paypalRQ = { itineraries: [], departureDate: '' };
                    paypalRQ.departureDate = bkgData.purchaseResponse.itineraries.first().departureDateTime;
                    bkgData.purchaseResponse.itineraries.each(function (itinerary) {
                        paypalRQ.itineraries.push(itinerary.origin.airportIata);
                        paypalRQ.itineraries.push(itinerary.destination.airportIata);
                    });

                    $scope.apiClient.getPayPalToken(paypalRQ).success(function (data) {
                        if (data)
                            bkgData.paypalToken = data.token;
                    }).error(function (data) {
                    }).finally(function () {
                    });
                }
            });

            $scope.priceByPaxType = function (paxType) {
                paxType = parseInt(paxType, 10);
                if (!$scope.paxTotalFlexPrices) {
                    return null;
                }
                var price = $scope.paxTotalFlexPrices.filter(function (f) {
                    return f.paxType === paxType;
                });
                if (price != null && price.length > 0) {
                    return price[0].priceAmount;
                }
                return 0;
            }

            $scope.updateScreen = function (step) {

                console.log("on passe la même au début step is : " + step);
                if ($location.hash() == "" && step == 1) {
                    //do nothing
                }
                else
                    $location.hash(step);

                console.log('hash:' + $location.hash());

                if ($location.hash() == 1 && $scope.accommodation != null) {

                    var accommodationKey = 'accommodation_' + $scope.accommodation.accommodationId;

                    if ($window.sessionStorage.getItem(accommodationKey) != null) {
                        accommodation = JSON.parse($window.sessionStorage.getItem(accommodationKey));
                        /*
                        getBundleWidget("mrfly-widget-accommodation-pax-form.js").done(function () {
                            $scope.BindPaxForm(accommodation, accommodationKey, bkgData.bookingRequest.passengers);
                        });
                        */
                    }
                }

                $scope.notReadyStep = step;
                bkgData.currentStep = step;
                bookingRequest.value = bkgData.bookingRequest;
                insurancesSelected.value = bkgData.insurancesSelected;
                insuranceOnce.value = bkgData.insuranceOnce;
                bookingRequestKey.value = keyGenerated;
                purchaseResponse.value = bkgData.purchaseResponse;
                //$window.sendAnalytics('/Flightsearch/Purchase' + step, 'Purchase' + step);

                //Tracking
                try {
                    //GTM
                    if (trackingService.isActivated()) {
                        trackingService.pushPageData('/Flightsearch/Purchase' + step, 'Purchase' + step, lng, false);
                    }
                }
                catch (ex) {
                    console.log(ex.message);
                    $window.trackingError = ex.message;
                }

                $window.sendInspectlet('virtualPage');
                $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Purchase' + step));
                $scope.updateBookingSummary();

                if (step == 2) {
                    try {
                        if (trackingService.isActivated()) {
                            if (trackingService.interactionEvent) {
                                trackingService.interactionEvent("funnel_step1bis", "inssurance", "1b");
                            }
                        }
                    } catch (ex) {
                        console.log(ex);
                    }
                }

                if (step == 3) {

                    try {
                        //GTM
                        if (trackingService.isActivated()) {
                            if ($scope.bkgData.insuranceOnce) {
                                trackingService.addProduct(gtmProduct.parseInsurance($scope.bkgData.insurancesSelected));
                            }
                            else {
                                trackingService.removeProduct('Insurance11');
                            }
                            trackingService.parseScope($scope);
                            trackingService.pushDataLayer('step', 3);
                            if (trackingService.interactionEvent) {
                                trackingService.interactionEvent("funnel_step2", "payer information", "2");
                            }
                        }
                        //omniture
                        if (omnitureService.isActivated()) {
                            console.info("=========================  send ctm TUnnel 3 -- 1/1 =================");
                            omnitureService.sendCdtm(omnitureService.PURCHASE_3_VOL, $scope.bkgData);
                            omnitureService.sendEvent(omnitureService.PURCHASE_3_VOL, omnitureService.FLIGHT_TUNNEL, null);
                        }
                    } catch (ex) {
                        console.log(ex);
                    }
                }
                if (bkgData && bkgData.purchaseResponse) {
                    if ($('#i-loading-cover').length) {
                        $window.FLoading({ vAction: 'hide' });
                    }
                }

                if (trackingService.isActivated() && window.partnerName == "aeroports-voyages") {

                    if (step == 2) {
                        trackingService.neoDataLayer('flight_option');
                    }

                    if (step == 3) {
                        trackingService.neoDataLayer('flight_insurance');
                    }
                }
            };

            //Update Booking Summary: Event to notify PurchaseSummary directive that there are changes
            $scope.updateBookingSummary = function () {
                $scope.$broadcast("onBookingSummaryUpdate");
            };

            $scope.$on("updateTotal", function (event, args) {

                if (args && args.partnerHotelMargin != null) {
                    var mar = (100 / (100 - args.partnerHotelMargin) * $scope.accommodation.totalPriceRacknet) - $scope.accommodation.totalPriceRacknet;

                    var margin = Math.ceil(mar * 100) / 100;

                    mrflyWidgets['mrfly-widget-accommodation-info'].agencyMarginHotel = margin;
                    bkgData.agencyMarginHotel.margin = args.partnerHotelMargin;
                    bkgData.agencyMarginHotel.value = margin;
                }

                if (mrflyWidgets['mrfly-widget-basket-total-price'] != null) {
                    mrflyWidgets['mrfly-widget-basket-total-price'].price = $scope.bkgData.totalBasketPrice();

                    var extraDetails = [];

                    var bags = basketHelper.getAdditionalBagsPriceDetails($scope.siteType, $scope.bkgData.bookingRequest.passengers);
                    var insurances = basketHelper.getInsurancePriceDetails($scope.siteType, $scope.bkgData.bookingRequest.insurance, $scope.bkgData.purchaseResponse.proposalsRequested.insurances);

                    Array.prototype.push.apply(extraDetails, bags);
                    Array.prototype.push.apply(extraDetails, insurances);

                    //Seatmap
                    var seatmapObj = JSON.parse(window.sessionStorage.getItem('selectedSeats_' + $scope.bkgData.purchaseResponse.purchaseId));
                    console.log(">>----");
                    console.log(seatmapObj);
                    if (seatmapObj != null) {
                        var seatmap = basketHelper.getSeatMapPriceDetails($scope.siteType, seatmapObj);
                        Array.prototype.push.apply(extraDetails, seatmap);
                    }

                    var basketDetails = basketHelper.getPriceDetails(
                        $scope.siteType,
                        $scope.bkgData.purchaseResponse.proposalsRequested,
                        $scope.accommodations,
                        siteTypeIsB2b ? bkgData.agencyMarginHotel : { value: 0, margin: 0 },
                        extraDetails
                    );
                    console.log("basketDetails");
                    console.log(basketDetails);
                    console.log("----- updateTotal ----------");

                    mrflyWidgets['mrfly-widget-basket-total-price'].basketDetails = basketDetails;
                }

                $scope.updateBookingSummary();
            });

            $scope.$on("selectedSeats", function (event, args) {
                $scope.updateBookingSummary();
            });

            $scope.$on("totalFlexySelected", function (event, args) {
                bkgData.totalFlexAmount = args.amount;
                bkgData.totalFlexSelected = args.selected;
                bkgData.textFlexSelected = args.textFlexSelected;
                bkgData.titleDescriptionFlexyItems = args.titleDescriptionFlexyItems;
                bkgData.flexyReference = args.flexyReference;
                bkgData.flexyId = args.flexyId;
                bkgData.bookingRequest.flexyId = args.flexyId;
                $scope.paxTotalFlexPrices = args.paxTotalFlexPrices;
                $scope.updateBookingSummary();
            });

            $scope.$on("onBookingSummaryUpdateCompleted", function (event, args) {


                $scope.prixTotalWithoutMargin = bkgData.prixTotalWithoutMargin;
                //update vue component if required
                if (typeof mrflyWidgets !== 'undefined' && mrflyWidgets != null &&
                    typeof mrflyWidgets['mrfly-widget-basket-total-price'] !== 'undefined' && mrflyWidgets['mrfly-widget-basket-total-price'] != null) {
                    mrflyWidgets['mrfly-widget-basket-total-price'].price = $scope.bkgData.totalBasketPrice();
                    //Handle extra bag
                    console.log("------------onBookingSummaryUpdateCompleted-------------");

                    var extraDetails = [];

                    var bags = basketHelper.getAdditionalBagsPriceDetails($scope.siteType, $scope.bkgData.bookingRequest.passengers);
                    var insurances = basketHelper.getInsurancePriceDetails($scope.siteType, $scope.bkgData.bookingRequest.insurance, $scope.bkgData.purchaseResponse.proposalsRequested.insurances);

                    Array.prototype.push.apply(extraDetails, bags);
                    Array.prototype.push.apply(extraDetails, insurances);

                    //Seatmap
                    var seatmapObj = JSON.parse(window.sessionStorage.getItem('selectedSeats_' + $scope.bkgData.purchaseResponse.purchaseId));
                    console.log(">>----");
                    console.log(seatmapObj);
                    if (seatmapObj != null) {
                        var seatmap = basketHelper.getSeatMapPriceDetails($scope.siteType, seatmapObj);
                        Array.prototype.push.apply(extraDetails, seatmap);
                    }
                    var basketDetails = basketHelper.getPriceDetails(
                        $scope.siteType,
                        $scope.bkgData.purchaseResponse.proposalsRequested,
                        $scope.accommodations,
                        siteTypeIsB2b ? $scope.bkgData.agencyMarginHotel : { value: 0, margin: 0 },
                        extraDetails
                    );
                    console.log("basketDetails");
                    console.log(basketDetails);
                    mrflyWidgets['mrfly-widget-basket-total-price'].basketDetails = basketDetails;

                }
            });

            $scope.$on("onFlexyClick", function (event, args) {
                bkgData.bookingRequest.flexyFare = args.isFlexy;
                bkgData.bookingRequest.flexyId = args.flexyId;
                bkgData.bookingRequest.insurance = null;
                bkgData.insuranceOnce = 0;
                bkgData.insurancesSelected = {};
                $scope.updateBookingSummary();
            });

            $scope.$on("onInsuranceClick", function (event, args) {
                bkgData.insuranceOnce = 1;
                bkgData.insurancesSelected.isAnnul = {};
                bkgData.insurancesSelected.isRepatriament = {};
                bkgData.insurancesSelected.isPrices = {};
                bkgData.insurancesSelected.isAnnulRepatriament = {};
                if (args.amount > 0) {
                    //TODO FFV2: replace references by types
                    //InsuranceReference = numéro de la référence de l’assurance paramétré dans pilato
                    //00001 = Assurance annulation
                    //00002 = Assurance annulation + Assistance rapatriement
                    //00003 = Assistance rapatriement
                    //00004 = Assistance rapatriement pour FreeFlex
                    if (args.type == "00001" || args.type == "00006" || args.type == "00007") { bkgData.insurancesSelected.isAnnul = { amount: args.amount, reference: args.type, company: args.company, quantity: args.quantity } }
                    if (args.type == "00005" || args.type == "00009" || args.type == "00009_bis" || args.type == "00010" || args.type == "00011") { bkgData.insurancesSelected.isRepatriament = { amount: args.amount, reference: args.type, company: args.company, quantity: args.quantity } }
                    if (args.type == "00002" || args.type == '00008') { bkgData.insurancesSelected.isAnnulRepatriament = { amount: args.amount, reference: args.type, company: args.company, quantity: args.quantity } }
                    bkgData.bookingRequest.insurance = { company: args.company, reference: args.type, id: args.id };
                }
                else if (args.amount === 0 && args.type === "00004" && window.partnerName === "volcdiscount") { //free insurance for cdiscount
                    bkgData.insurancesSelected.isRepatriament = { amount: args.amount, reference: args.type, company: args.company, quantity: args.quantity };
                    bkgData.bookingRequest.insurance = { company: args.company, reference: args.type, insuranceId: args.id };
                }
                else if (bkgData.bookingRequest.insurance != null && args.type == bkgData.bookingRequest.insurance.reference) {
                    bkgData.bookingRequest.insurance = null;
                }
                bkgData.insurancesSelected.isPrices = args.prices;
                insurancesSelected.value = bkgData.insurancesSelected;
                insuranceOnce.value = bkgData.insuranceOnce;
                $scope.updateBookingSummary();
            });

            //Update Itinerary BookingItinerary; Event to notify PurchaseSummary directive that there are changes        
            $scope.updateItinerarySummary = function () {
                $scope.$broadcast("onItinerarySummaryUpdate");
            }

            //OnLoad
            if ($location.hash() != "") {
                var normalizedHash = $location.hash().replaceAll('#', '').substring(0);
                var step = parseInt(normalizedHash);
                if (step > 0) $scope.updateScreen(step);
                else $scope.updateScreen(1);
            }
            else
                $scope.updateScreen(1);

            //Detect if hash changes.
            var hashChanged = function (callback) {
                var lastHash = $location.hash(); //window.location.hash;
                var innerFunc = function () {
                    if ($location.hash() != lastHash) {
                        lastHash = $location.hash();
                        if (callback)
                            callback(lastHash);
                    }
                    setTimeout(innerFunc, 100);
                };
                setTimeout(innerFunc, 100);
            };
            hashChanged(function (hashValue) {
                if (hashValue == "") hashValue = "#1";
                var step = parseInt(hashValue.substring(1));

                if (step > 0) {
                    $scope.$apply(function () {
                        if ($scope.notReadyStep != step) {
                            $scope.updateScreen(step);
                        }
                    });
                }
            });

            $scope.$on("setReadyStep", function (event, args) {
                $scope.newProcess = false;
                $('#i-field-error').hide();

                if (args.step == 1) {

                    var additionalBaggagesList = [];

                    if (bkgData.bookingRequest.bagChanged) {
                        bkgData.bookingRequest.passengers.each(function (p, index) {
                            //FFV2: has to give passenger index
                            if (p.baggageByItinerary != null) {
                                for (var bag in p.baggageByItinerary) {
                                    if (p.baggageByItinerary[bag].quantity > 0) {
                                        additionalBaggagesList.push({
                                            passengerIndex: index,
                                            additionalBaggage: p.baggageByItinerary[bag].key
                                        });
                                    }
                                }
                            }
                        });
                    }

                    //TODO FFV2: get flexyID once P1 validated & set it into bookingRequest
                    //var flexyId = $window.sessionStorage.getItem("inssurance.totalflex.selectedOffer");
                    //bkgData.bookingRequest.flexyId = flexyId;

                    bkgData.additionalBaggagesList = additionalBaggagesList;

                    //Si hay maletas adicionales seleccionadas llamamos a la transaccion PURCHASE.calculateInsurance
                    if (additionalBaggagesList.any() || bkgData.bookingRequest.bagChanged) {

                        $window.FLoading({ vAction: 'showNow' });
                        var calculateInsurance = {};
                        calculateInsurance.requestId = $scope.bkgData.bookingRequest.requestId;
                        calculateInsurance.purchaseId = $scope.bkgData.purchaseResponse.purchaseId;
                        calculateInsurance.index = $scope.bkgData.purchaseResponse.proposalsRequested.index;
                        calculateInsurance.additionalBaggages = additionalBaggagesList;

                        //TODO FFV2: update api call
                        $scope.apiClient.PURCHASE.calculateInsurance(calculateInsurance).success(function (data) {
                            if (data && data.insurances && data.insurances.length > 0) {
                                $scope.bkgData.purchaseResponse.proposalsRequested.insurances = data.insurances;

                                //required so that the insurance rap. is reloaded
                                $scope.$broadcast("insurancesupdated");

                                bkgData.bookingRequest.bagChanged = false;
                                $window.FLoading({ vAction: 'hide' });
                                $(".c-footer").removeClass("d-none");
                            }
                            else {
                                $window.FLoading({ vAction: 'hide' });
                                $(".c-footer").removeClass("d-none");
                            }
                        }).error(function (data) { });
                    }

                    if ($scope.hideInsurance == true) {
                        var purchasePaymentMethodsRq = {
                            value: 2,
                            purchaseId: $scope.bkgData.purchaseResponse.purchaseId,
                            proposalIndex: $scope.bkgData.bookingRequest.proposalIndex,
                            passengers: $scope.bkgData.bookingRequest.passengers
                        };

                        $scope.$emit("getPurchasePaymentMethods", purchasePaymentMethodsRq);
                    }
                }

                $scope.updateScreen(args.step + 1);
            });

            //Additional baggages has been changed
            $scope.$on("addBaggagesChanged", function (event, args) {
                bkgData.bookingRequest.needToRecalculSubvention = true;
                $scope.updateBookingSummary();
                bkgData.bookingRequest.bagChanged = true;
            });

            $scope.$on("onBestPriceClick", function (event, args) {
                bkgData.bookingRequest.bestPriceFare = args.isBestPrice;
                $scope.updateBookingSummary();
            });

            $scope.$on("brandClick", function (event, args) {
                if (args.brandKey != null && args.proposalIndex != null) {
                    bkgData.bookingRequest.proposalIndex = args.proposalIndex;
                    bkgData.bookingRequest.brandKey = args.brandKey;

                    airHelper.selectProposal(args.proposalIndex, bkgData.purchaseResponse);

                    //fix brandes fares class for train
                    if ($scope.bkgData.purchaseResponse.productIsTrain === true) {
                        var reference = 0;
                        bkgData.purchaseResponse.proposals[args.proposalIndex].fares.each(function (fare) {
                            fare.itineraries.each(function (itinerary) {
                                itinerary.segments.each(function (segment) {
                                    if (segment.cabinClass.reference > reference) {
                                        reference = segment.cabinClass.reference;
                                    }
                                });
                            });
                        });

                        if (reference > 0) {
                            bkgData.request.cabinClass = reference == 1 ? 'C' : 'Y';
                        }
                    }

                    $scope.updateBookingSummary();
                    $scope.updateItinerarySummary();

                    $scope.fareWithLugage = args.fareWithLugage;
                    $scope.fareWithoutBaggage = args.fareWithoutBaggage;

                    if (mrflyWidgets['mrfly-widget-itinerary-recap']) mrflyWidgets['mrfly-widget-itinerary-recap'].enableaddluggages = args.fareWithoutBaggage;
                    if (bkgData.purchaseResponse.proposalsRequested.fareWithoutBaggage == false)
                        $scope.$broadcast("onNoAddBaggagesSelection");
                }
            });

            var changePaxesAndPayer = true;
            var paxHistory = tseUtil.Session.get('flightPaxHistory');

            if (paxHistory != undefined && paxHistory.request != undefined && bkgData.request != undefined && $scope.shouldAutoFill && init) {
                if (paxHistory.request.adults != bkgData.request.adults)
                    changePaxesAndPayer = false;
                if (paxHistory.request.childs != bkgData.request.childs)
                    changePaxesAndPayer = false;
                if (paxHistory.request.infants != bkgData.request.infants)
                    changePaxesAndPayer = false;
            }
            else {
                changePaxesAndPayer = false;
            }

            if (changePaxesAndPayer) {
                bkgData.bookingRequest.passengers = paxHistory.passengers;
                if (paxHistory.buyer != undefined)
                    bkgData.bookingRequest.buyer = paxHistory.buyer;
                if (paxHistory.contact != undefined)
                    bkgData.bookingRequest.contact = paxHistory.contact;
                console.log("PaxHistory has been loaded.");
            }
            $scope.updateBookingSummary();
            bookingRequest.value = bkgData.bookingRequest;
            insurancesSelected.value = bkgData.insurancesSelected;
            insuranceOnce.value = bkgData.insuranceOnce;
            bookingRequestKey.value = keyGenerated;

            //PURCHASE Create Booking
            $scope.$on("createBooking", function (event, args) {

                //Save ecurrent url in sessionStorage for Casino 
                tseUtil.Session.set('Step3Url', window.location.href);
                if (args.request.paymentMethod.paymentMethodReference === "CAS") window.mrflyWidgets['airbooking-process-loader-cb4x'].$store.commit("showLoader");
                else window.mrflyWidgets['airbooking-process-loader'].$store.commit("showLoader");
                var argsRequest = window.objectClone(args.request);
                console.log("-- argsRequest --");
                console.log(argsRequest);

                var bookingRQ = {
                    purchaseId: argsRequest.purchaseId,
                    requestId: argsRequest.requestId,
                    proposalIndex: argsRequest.proposalIndex,
                    contact: argsRequest.contact,
                    buyer: argsRequest.buyer,
                    passengers: [],
                    insuranceId: argsRequest.insurance != null ? argsRequest.insurance.id : null,
                    partnerMargin: argsRequest.partnerMargin,
                    flexyId: argsRequest.flexyId,
                    agencyMargins: [],
                    additionalData: {},
                    preBook: argsRequest.paymentMethod.isOption == true ? true : false,
                    culture: $scope.selectDocumentsLanguage.toUpperCase()
                };
                
                bookingRQ.agencyMargins.push({
                    productType: 0,
                    margin: $scope.bkgData.getDpMarginFlight(argsRequest.partnerMargin)
                });

                //Add Accommodation
                if ($window.accommodation) {
                    bookingRQ.accommodations = [];
                    bookingRQ.accommodations.push($window.accommodation);

                    if (bkgData.agencyMarginHotel) {
                        bookingRQ.agencyMargins.push({
                            productType: 1,
                            margin: $scope.bkgData.getDpMarginHotel()
                        });
                    }
                }

                bookingRQ.paymentMethod = argsRequest.paymentMethod;
                if (window.site.partnerId === "cnas" || window.site.partnerId === "cnasdomtom") {
                    bookingRQ.additionalData["CNAS_CNAS_ID"] = argsRequest.passengers[0].partnerId;
                    bookingRQ.additionalData["CNAS_ CLE_NUM_ADH_AGENT"] = argsRequest.passengers[0].matricule;
                    bookingRQ.additionalData["CNAS_NOM_AGENT"] = argsRequest.passengers[0].lastName;
                }

                if (window.partnerName == "adpmobile" || window.partnerName == "adp") {
                    bookingRQ.additionalData["ExtimeNbPoints"] = $scope.bkgData.extimePoints;
                }
                //Airbus Additional data            
                if (user && user.type === 6 && user.loginInfo != null && user.loginInfo.Matricule != null) {

                    if (bkgData.bookingRequest.airbusPayOption != null && bkgData.bookingRequest.airbusPayOption != undefined) {
                        bookingRQ.additionalData["airbus.payBalanceOption"] = bkgData.bookingRequest.airbusPayOption;
                    }
                    if (argsRequest.additionalData != null) {
                        if (argsRequest.additionalData["airbus.firstname"] != null) {
                            bookingRQ.additionalData["airbus.firstname"] = argsRequest.additionalData["airbus.firstname"];
                        }
                        if (argsRequest.additionalData["airbus.lastname"] != null) {
                            bookingRQ.additionalData["airbus.lastname"] = argsRequest.additionalData["airbus.lastname"];
                        }
                        if (argsRequest.additionalData["airbus.matricule"] != null) {
                            bookingRQ.additionalData["airbus.matricule"] = argsRequest.additionalData["airbus.matricule"];
                        }
                        if (argsRequest.additionalData["airbus.matricule2"] != null && argsRequest.additionalData["airbus.matricule2"].length > 0) {
                            bookingRQ.additionalData["airbus.matricule2"] = argsRequest.additionalData["airbus.matricule2"];
                        }
                        if (argsRequest.additionalData["airbus.insurance"] != null) {
                            bookingRQ.additionalData["airbus.insurance"] = argsRequest.additionalData["airbus.insurance"];
                        }
                        if (argsRequest.additionalData["airbus.portable"] != null) {
                            bookingRQ.additionalData["airbus.portable"] = argsRequest.additionalData["airbus.portable"];
                        }
                        if (argsRequest.additionalData["airbus.telephone"] != null) {
                            bookingRQ.additionalData["airbus.telephone"] = argsRequest.additionalData["airbus.telephone"];
                        }
                        if (argsRequest.additionalData["airbus.referrer"] != null) {
                            bookingRQ.additionalData["airbus.referrer"] = argsRequest.additionalData["airbus.referrer"];
                        }
                    }
                }

                if (bkgData.bookingRequest.cECode != null && bkgData.bookingRequest.cECode != undefined) {
                    bookingRQ.additionalData["Ce.Code"] = bkgData.bookingRequest.cECode;
                }

                //Additional data
                if (argsRequest.holidayVoucher != undefined && argsRequest.holidayVoucher != false)
                    bookingRQ.additionalData["HolidayVoucher"] = argsRequest.holidayVoucher;

                if ($scope.bkgData.validDiscountCode != undefined && $scope.bkgData.validDiscountCode != null)
                    bookingRQ.additionalData["DiscountCode"] = $scope.bkgData.validDiscountCode;

                if (argsRequest.avaloirInitialBookingId != undefined && argsRequest.avaloirInitialBookingId != null)
                    bookingRQ.additionalData["AvaloirInitialBookingId"] = argsRequest.avaloirInitialBookingId;

                //gestion des valeurs dynamique de formulaires
                for (var key in argsRequest.customFields) {
                    bookingRQ.additionalData[key] = argsRequest.customFields[key];
                }

                if (user.type === 6 && user.loginInfo != null && user.loginInfo.Matricule != null) {
                    bookingRQ.ceUser = user.loginInfo.Matricule;
                }

                if (existWonderboxCode)
                    bookingRQ.additionalData["WonderboxCode"] = bkgData.bookingRequest.wonderboxCode;

                if ($window.sessionStorage.getItem("ooofly_opeId")) {
                    bookingRQ.additionalData["opeId"] = $window.sessionStorage.getItem("ooofly_opeId");
                }


                if ($window.sessionStorage.getItem("cdiscountCustomerGuid")) {
                    bookingRQ.additionalData["cdiscountCustomerGuid"] = $window.sessionStorage.getItem("cdiscountCustomerGuid");
                    bookingRQ.additionalData["cdiscountCustomerToken"] = $window.site.loginInfo.Token;
                }

                console.log("=============$scope.bkgData.co2Data ======>>>> ");
                console.log($scope.bkgData.co2Data);

                if (window.enableCO2 && $scope.bkgData.co2Data != undefined && $scope.bkgData.co2Data != null) {
                    var co2Average = 0;
                    for (var key in $scope.bkgData.co2Data) {
                        co2Average += $scope.bkgData.co2Data[key].co2Average;
                    }
                    bookingRQ.additionalData["co2Data"] = (co2Average * 1000).toFixed(0);

                    // Remplir donnée de l'aller --> outbound
                    var co2DataOutbound = $scope.bkgData.purchaseResponse.itineraries[0].co2Data;
                    if (co2DataOutbound != null && co2DataOutbound.hasAllSegmentsData) {
                        var dataOut = {
                            avgDev: Math.round(parseFloat(co2DataOutbound.co2AverageDeviation)),
                            avgCo2: Math.round(parseFloat(co2DataOutbound.co2Average) * 1000),
                            recalc: co2DataOutbound.isRecalculation
                        };
                        bookingRQ.additionalData["co2Data_aller"] = JSON.stringify(dataOut);
                    }

                    if ($scope.bkgData.request.roundTrip && $scope.bkgData.purchaseResponse.itineraries.length == 2) {
                        // Remplir donnée du retour --> inbound
                        var co2DataInbound = $scope.bkgData.purchaseResponse.itineraries[1].co2Data;
                        if (co2DataInbound != null && co2DataInbound.hasAllSegmentsData) {
                            var dataIn = {
                                avgDev: Math.round(parseFloat(co2DataInbound.co2AverageDeviation)),
                                avgCo2: Math.round(parseFloat(co2DataInbound.co2Average) * 1000),
                                recalc: co2DataInbound.isRecalculation
                            };
                            bookingRQ.additionalData["co2Data_retour"] = JSON.stringify(dataIn);
                        }
                    }
                }

                if (window.site.partnerId == "cic") {
                    bookingRQ.additionalData["idLead"] = getCookie("lid_idlead_cic");
                }

                if (window.site.partnerId == "qwertys") {
                    bookingRQ.additionalData["noDossier"] = $window.site.loginInfo.noDossier;
                }

                if (window.site.partnerId == "adosspp") {
                    bookingRQ.additionalData["adosspp_token"] = getCookie("lid_adosspp_token");
                    var beneficiaryFamily = [];
                    bkgData.bookingRequest.passengers.forEach(function (passenger) {
                        if (passenger.id != 0) {
                            beneficiaryFamily.push({
                                "id": passenger.externalUserId,
                                "firstName": passenger.firstName,
                                "lastName": passenger.lastName
                            });
                        }
                    });
                    bookingRQ.additionalData["adosspp_beneficiaryFamily"] = JSON.stringify(beneficiaryFamily);
                }

                if ($window.sessionStorage.getItem("hasCdav"))
                    bookingRQ.additionalData["hasCdav"] = $window.sessionStorage.getItem("hasCdav");

                //AdditionalData in URL for WHITE LABELS
                if ($scope.enableSendParameters) {
                    var customURL = tseUtil.Location.getQueryStringVar("custom");
                    var campaignURL = tseUtil.Location.getQueryStringVar("campaign");

                    if (customURL) {
                        bookingRQ.additionalData["custom"] = customURL;
                    }

                    if (campaignURL) {
                        bookingRQ.additionalData["campaign"] = campaignURL;
                    }
                }

                argsRequest.passengers.each(function (pax) {
                    var auxPaxPassportExpireDate = null;
                    var auxPaxBirthDate = moment(pax.birthDate, "DD-MM-YYYY");

                    if (pax.passportExpireDate != undefined)
                        auxPaxPassportExpireDate = moment(pax.passportExpireDate, "DD-MM-YYYY");

                    var auxPasenger = {
                        firstName: pax.firstName,
                        lastName: pax.lastName,
                        birthDate: auxPaxBirthDate.format("YYYY-MM-DD"),
                        index: pax.index,
                        type: pax.paxType,
                        phoneCountryCode: pax.phoneCountryCode,
                        ptc: pax.ptc,
                        title: pax.title,
                        passportNumber: pax.passportNumber,
                        passportCountry: pax.passportCountry,
                        additionalBaggages: [],
                        fidelityCardNumber: pax.fidelityCardNumber,
                        fidelityCard: pax.fidelityCardType,
                        totalFlexy: $scope.priceByPaxType(pax.paxType),
                    };
                    if (pax.phoneCountryCode !== undefined && pax.phoneCountryCode !== '' && pax.phone !== undefined && pax.phone !== '') {

                        var parsedNumber = libphonenumber.parsePhoneNumberFromString("+{0}{1}".format(pax.phoneCountryCode, pax.phone));

                        if (parsedNumber !== undefined) {
                            auxPasenger.phone = parsedNumber.format("INTERNATIONAL").replace(/\s/g, "");
                        }
                    }


                    if (auxPaxPassportExpireDate != undefined)
                        auxPasenger.passportExpiry = auxPaxPassportExpireDate.format("YYYY-MM-DD");

                    if (pax.baggageByItinerary) {
                        for (var paxBaggage in pax.baggageByItinerary) {
                            if (pax.baggageByItinerary[paxBaggage].quantity > 0) {
                                if (pax.baggageByItinerary[paxBaggage]) {
                                    auxPasenger.additionalBaggages.push({
                                        itineraryKey: paxBaggage,
                                        baggageKey: pax.baggageByItinerary[paxBaggage].key,
                                        baggageId: pax.baggageByItinerary[paxBaggage].baggageId
                                    });
                                }
                            }
                        }
                    }

                    bookingRQ.passengers.push(auxPasenger);
                });

                //Tracking
                try {
                    //GTM
                    if (trackingService.isActivated()) {
                        trackingService.pushPageData('/Flightsearch/Bookingwait', 'Booking wait', lng, false);
                    }
                }
                catch (ex) {
                    console.log(ex.message);
                    $window.trackingError = ex.message;
                }

                $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Bookingwait'));
                window.sendDoubleClick("recapvol", null, null, null, null);

                if (bookingRQ.accommodations != null && bookingRQ.accommodations.any()) {
                    if (bookingRQ.accommodations[0].rooms != null) {
                        bookingRQ.accommodations[0].rooms.each(function (room) {
                            if (room.passengers != null) {
                                //clone passengers to avoid error in date picker on back navigation
                                var passengers = $window.objectClone(room.passengers);
                                passengers.each(function (passenger) {
                                    var date = passenger.birthDate.toString();
                                    var newDate = date.substring(6, 10) + "-" + date.substring(3, 5) + "-" + date.substring(0, 2);
                                    passenger.birthDate = newDate;
                                });
                                room.passengers = passengers;
                            }
                        });
                    }
                }

                var selectedSeatsKey = 'selectedSeats_' + bookingRQ.purchaseId;

                var sessionSeats = window.sessionStorage.getItem(selectedSeatsKey);
                if (sessionSeats != null) {
                    var selection = JSON.parse(sessionSeats);

                    angular.forEach(selection, function (segment, key) {

                        var segmentKey = key;

                        angular.forEach(selection[key], function (select, key) {

                            for (var i = 0; i < bookingRQ.passengers.length; i++) {
                                if (bookingRQ.passengers[i].index == select.passenger.index) {
                                    if (bookingRQ.passengers[i].segmentSeats == null) {
                                        bookingRQ.passengers[i].segmentSeats = [];
                                    }
                                    var segementSeat = {
                                        //choice :,
                                        segmentKey: segmentKey,
                                        //deckLevel = ss.DeckLevel,
                                        //coach = ss.Coach,
                                        providerKey: select.seat.providerKey,
                                        //rowIndex = ss.RowIndex,
                                        seatNumber: select.seat.number
                                    };

                                    bookingRQ.passengers[i].segmentSeats.push(segementSeat);

                                    console.log("PAX SEATS");
                                    console.log(bookingRQ.passengers[i].segmentSeats);
                                }
                            }

                        });
                    });
                }

                if (trackingService.isActivated() && window.partnerName == "aeroports-voyages") {
                    trackingService.neoDataLayer('add_payment_info');
                }

                //Call to API
                $scope.apiClient.PURCHASE.createBooking(bookingRQ)
                    .success(function (data, code) {
                        if (data && data.bookingId) {
                            console.log("SUCCES DE CREATE BOOKING !!! =====>>> ");
                            console.log(data);
                            var labels = new Object();
                            labels['status'] = "OK";
                            window.Typescript.Services.MetricService.SendEvent(window.metricUrlApi, "createBooking", labels);
                            if ($scope.assistanceRequests) {
                                // PUT SPECIAL REQUEST 
                                var data;
                                var urlApi = "";
                                switch ($scope.assistanceRequests[0].type) {
                                    case 0://ASSISTANCE
                                        urlApi = "/api/api/specialrequest/PutAssistanceRequestAsync";
                                        break;
                                    case 1:
                                        //ANIMAL
                                        urlApi = "/api/api/specialrequest/PutPetsRequestAsync";
                                        break;
                                    case 2:
                                        //LUGGAGE
                                        urlApi = "/api/api/specialrequest/PutLuggageRequestAsync";
                                        break;
                                }
                                $scope.assistanceRequests[0].bookingId = data.bookingId;
                                $scope.assistanceRequests[0].agentId = $window.site.loginInfo.Agent.AgentId;
                                var dataRequest = JSON.stringify($scope.assistanceRequests);
                                $.ajax({
                                    url: urlApi,
                                    type: 'post',
                                    dataType: 'json',
                                    contentType: 'application/json; charset=utf-8',
                                    data: dataRequest,
                                    async: false,
                                    success: function (result) {
                                        if (result) {
                                            sessionStorage.removeItem('assistanceRequestStep1');
                                            localStorage.setItem('assistancesRequest' + '-' + data.bookingId, JSON.stringify(dataRequest));
                                        }
                                    },
                                    error: function (result, status, error) {
                                    }
                                });
                            }

                            bkgData.bookingResponse = data;

                            var paymentUrl = "";
                            //OK saved!
                            tseUtil.Session.set('bkgData_' + $window.site.currentAirRequest.requestId, bkgData);
                            paymentUrl = tseUtil.Url.appendValueToUrlBefore($window.site.currentAirRequest.paymentUrl, "brqid", data.bookingId);
                            if (bookingRQ.paymentMethod.paymentType) {
                                paymentUrl = tseUtil.Url.appendValueToUrlBefore(paymentUrl, "paymenttype", bookingRQ.paymentMethod.paymentType);
                                paymentUrl = tseUtil.Url.appendValueToUrlBefore(paymentUrl, "selectedpayment", bkgData.selectedPayment);
                            }

                            if (paymentUrl !== "") {

                                if (bookingRQ.paymentMethod.paymentType === "CASINOCB4X" && !window.disableBlue) {//blue disabled in web.config
                                    //CASINO
                                    var result = window.Typescript.Services.OrderServiceService.GetBankCasinoSchedule($window.forceLemmingsHost,
                                        $window.site.currentAirRequest.requestId,
                                        data.bookingId,
                                        data.paymentId,
                                        $scope.bkgData.bookingRequest.bankCasino.departmentOfBirth,
                                        $scope.bkgData.bookingRequest.bankCasino.birthDate,
                                        $scope.bkgData.bookingRequest.bankCasino.maidenName);

                                    result.then(function (data) { //get redirection url from C# controler
                                        console.log("GetBankCasinoSchedule");
                                        console.log(data);
                                        console.log(data.data);
                                        var fgf = data;
                                        if (data != null && data.data != null && data.data.widgetUrl != "") {
                                            window.location.href = paymentUrl;
                                        } else {
                                            $scope.$broadcast('casinoschedule-failed');
                                        }
                                    }).catch(function (err) { //on exception, redirect on error page
                                        $scope.$broadcast('casinoschedule-failed');
                                    });

                                }
                                else {
                                    $window.location.href = paymentUrl;
                                }
                            }

                        }
                    }).error(function (data, code) {
                        console.error(data);
                        var labels = new Object();
                        labels['status'] = "KO";
                        window.Typescript.Services.MetricService.SendEvent(window.metricUrlApi, "createBooking", labels);

                        window.mrflyWidgets['mrfly-widget-loader-component'].$store.commit("hideLoader");
                        $(".c-footer").removeClass("d-none");
                        if (code == 400) {
                            $window.XLConfirm({
                                vTitle: $i18next.t("airbooking.yoursessionlost"), vTextYes: "OK", vHideNo: true, fCallBackYes: function () {
                                    $window.location.href = $window.site.loginUrl;
                                }
                            });
                        }
                        if (code == 410) {
                            $window.XLConfirm({
                                vTitle: $i18next.t("airbooking.yoursessionlost"), vTextYes: "OK", vHideNo: true, fCallBackYes: function () {
                                    $window.redoSearch();
                                }
                            });
                        }
                        if (code == 412) {
                            $window.XLConfirm({
                                vTitle: $i18next.t("airbooking.pleasetheform"), vTextYes: "OK", vHideNo: true
                            });
                        }
                    }).finally(function () {

                    });
            });



            //PURCHASE Payment Methods
            $scope.$on("getPurchasePaymentMethods",
                function (event, args) {
                    var argsPaymentMethodsRequest = window.objectClone(args);
                    var nextStep = argsPaymentMethodsRequest.value;

                    $window.FLoading({ vAction: 'show' });

                    var result = $window.Typescript.Services.OrderServiceService.GetPaymentMethods($window.forceLemmingsHost, $scope.bkgData.totalBasketPrice());

                    result.then(function (response) { //get redirection url from C# controler
                        if (response.data != null) {
                            console.log(response);
                            $window.FLoading({ vAction: 'hide' });
                            $(".c-footer").removeClass("d-none");
                            if (response.data) {
                                console.log(response.data);
                                bkgData.paymentMethods = response.data.paymentMethods;
                            }
                            if (nextStep >= 0)
                                $scope.$emit("setReadyStep", { step: nextStep });
                            $scope.$broadcast("getBanqueCasinoScheduleCallback");
                        }
                    }).catch(function (err) { //on exception, redirect on error page
                        console.log(err);

                        $window.FLoading({ vAction: 'hide' });
                        $(".c-footer").removeClass("d-none");
                        if (nextStep >= 0)
                            $scope.$emit("setReadyStep", { step: nextStep });
                        $scope.$broadcast("getBanqueCasinoScheduleCallback");
                    });


                });



            //Prediction Object
            $scope.preparePredictionsObject = function () {
                //Get Price Predictions
                var rqPredictions = {
                    price: bkgData.purchaseResponse.proposals[0].totalAmount.value,
                    itineraries: []
                };
                bkgData.purchaseResponse.itineraries.each(function (it) {
                    var itAux = {
                        origin: it.origin.airportIata,
                        destination: it.destination.airportIata,
                        airline: it.marketingCarrier.iata,
                        stops: it.segments.length - 1,
                        date: it.date
                    };
                    rqPredictions.itineraries.push(itAux);
                });
                return rqPredictions;
            };


            //Taxes details click
            $scope.$on("displayTaxesDetailsClick", function (event, args) {
                $scope.displayTaxesDetailModal = true;
                $scope.modalTaxesKey = args.key.replaceAll(":", "-");
                $scope.modalTaxesKey = $scope.modalTaxesKey.replaceAll("\\|", "-");
                $scope.modalTaxesKeyAux = $scope.modalTaxesKey + "-" + args.paxFares.paxType;
                $scope.modalTaxesPaxFares = args.paxFares;

                $scope.$broadcast("updateTaxesDetailModalData", { key: $scope.modalTaxesKey, fares: $scope.modalTaxesPaxFares });

                $scope.modalShowMargin = false;

                var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

                args.marketingCarriers.each(function (value, key) {

                    if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                        $scope.modalShowMargin = true;
                    }
                });

                //Remodal workaround
                $(document).find(".remodal").each(function (a, c) {
                    var d = $(c), e = d.data("remodal-options");
                    e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                        d["remodal"](e)
                });

                $timeout(function () {
                    $("[data-remodal-id='taxes-detail-modal-" + $scope.modalTaxesKeyAux + "']").remodal().open();
                },
                    200, false);
            });

            //Luaggage details click
            $scope.$on("displayLuggageDetailsClick", function (event, args) {
                $scope.displayLuggageDetailModal = true;
                $scope.modalLuggageBagType = args.bagType;

                $scope.$broadcast("updateLuggageDetailModalData", { bagType: $scope.modalLuggageBagType });

                $scope.modalShowMargin = false;

                var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

                args.marketingCarriers.each(function (value, key) {

                    if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                        $scope.modalShowMargin = true;
                    }
                });

                //Remodal workaround
                $(document).find(".remodal").each(function (a, c) {
                    var d = $(c), e = d.data("remodal-options");
                    e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                        d["remodal"](e)
                });

                $timeout(function () {
                    $('[data-remodal-id="luggage-detail-modal"]').remodal().open();
                },
                    200, false);
            });

            //Seats details click
            $scope.$on("displaySeatDetailsClick", function (event, args) {
                $scope.displaySeatDetailModal = true;
                $scope.modalSeat = args.seat;

                $scope.$broadcast("updateSeatDetailModalData", { seatDescription: $scope.modalSeat });

                $scope.modalShowMargin = false;

                var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

                args.marketingCarriers.each(function (value, key) {

                    if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                        $scope.modalShowMargin = true;
                    }
                });

                //Remodal workaround
                $(document).find(".remodal").each(function (a, c) {
                    var d = $(c), e = d.data("remodal-options");
                    e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                        d["remodal"](e)
                });

                $timeout(function () {
                    $('[data-remodal-id="seat-detail-modal"]').remodal().open();
                },
                    200, false);
            });

            //AIRBUS
            $scope.$on("selectAirbusInsurance", function (event, args) {
                bkgData.bookingRequest.isAirbusInsuranceSelected = true;
                bkgData.bookingRequest.isAirbusInsuranceUncheked = false;
            });

            $scope.$on("rejectAirbusInsurance", function (event, args) {
                bkgData.bookingRequest.isAirbusInsuranceSelected = false;
                bkgData.bookingRequest.isAirbusInsuranceUncheked = true;
            });

            $scope.$on("selectAirbusPaymentBalanceOption", function (event, args) {
                bkgData.bookingRequest.airbusPayOption = args.airbusPayOption;
            });

            $scope.$on("onAssistanceRequestSave", function (event, assistanceRequests) {
                $scope.assistanceRequests = assistanceRequests;
            });
        }
        else {
            $window.FLoading({ vAction: 'show' });
            window.location.href = searchUrl;
        }
    }]);